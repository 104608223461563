import moment from 'moment';
import { Box, TableCell, TableRow } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { TrustAccountShort } from '../../../types';
import FirmBankingTableActionButton from '../FirmBankingTableActionButton';
import OptionsMenu from '../OptionsMenu';
import { MenuOption, TrustAccountTableItem } from '../types';

interface FirmTrustAccountTableRowProps {
  data: TrustAccountShort[];
  onEditButtonClick: (accountId: number, option: MenuOption) => void;
  onDeleteButtonClick: (accountId: number) => void;
}

const FirmTrustAccountTableRow = ({
  data,
  onEditButtonClick,
  onDeleteButtonClick,
}: FirmTrustAccountTableRowProps) => {
  const formattedTableData: TrustAccountTableItem[] = data.map((account) => ({
    createdAt: moment(account.createdAt).format('M/D/YYYY'),
    id: account.id,
    name: account.name,
    type: account.type,
    accountNumber: account.accountNumber,
    routingNumber: account.routingNumber,
    status: account.status,
    isEwsVerified: account.isEwsVerified ? 'Verified' : 'Unverified',
    canBeDeleted: account.canBeDeleted,
  }));

  return (
    <>
      {formattedTableData.map((tableItem) => (
        <TableRow key={tableItem.id}>
          {Object.entries(tableItem).map(([key, value], idx) => {
            if (key === 'id' || key === 'canBeDeleted') return;

            return <TableCell key={idx}>{value}</TableCell>;
          })}
          <TableCell>
            <Box display="flex" gap="12px" alignItems="center">
              <OptionsMenu onClick={(option) => onEditButtonClick(tableItem.id, option)} />
              <FirmBankingTableActionButton
                type="error"
                buttonIcon={
                  <DeleteOutlineOutlinedIcon
                    sx={{
                      fontSize: '20px',
                    }}
                  />
                }
                disabled={!tableItem.canBeDeleted}
                handleClick={() => onDeleteButtonClick(tableItem.id)}
              />
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default FirmTrustAccountTableRow;
