import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { getDeductionTimeline } from '../../../api/paymentsApi/deductionsApi';
import Timeline from '../../../components/Timeline';
import { TimelineEvent } from '../../../types';

const DeductionsTimeline = () => {
  const { deductionId } = useParams<string>();
  const [deductionTimeline, setDeductionTimeline] = useState<TimelineEvent[]>([]);

  useEffect(() => {
    if (!deductionId) {
      return;
    }

    getDeductionTimeline(Number(deductionId)).then((res) => {
      if (res.data) {
        setDeductionTimeline(res.data);
      }
    });
  }, []);

  return <Timeline data={deductionTimeline} />;
};

export default DeductionsTimeline;
