import { palette } from '../../palette';
import {
  ProvisioningStatus,
  ExternalAccountStatus,
  EwsValidationStatus,
  StatusMap,
} from '../../types';

export const ProvisioningStatusMap: StatusMap<ProvisioningStatus> = {
  NotStarted: { color: palette.warning.main, label: 'Not Started' },
  InProgress: { color: palette.warning.main, label: 'In Progress' },
  Error: { color: palette.error.main, label: 'Error' },
  CriticalError: { color: palette.error.main, label: 'Critical Error' },
  Completed: { color: palette.success.main, label: 'Completed' },
};

export const ExternalAccountStatusMap: StatusMap<ExternalAccountStatus> = {
  INACTIVE: { color: palette.warning.main, label: 'Inactive' },
  PENDING_VERIFICATION: { color: palette.warning.main, label: 'Pending Verification' },
  ACTIVE: { color: palette.success.main, label: 'Active' },
  BLOCKED: { color: palette.warning.main, label: 'Blocked' },
};

export const EwsValidationStatusMap: StatusMap<EwsValidationStatus> = {
  PENDING: { color: palette.warning.main, label: 'Pending' },
  NOT_FOUND: { color: palette.error.main, label: 'Not Found' },
  CLOSED: { color: palette.error.main, label: 'Closed' },
  HIGH_RISK: { color: palette.error.main, label: 'High Risk' },
  INVALID_DEBIT_ACCOUNT: { color: palette.error.main, label: 'Invalid Debit Account' },
  OPEN: { color: palette.success.main, label: 'Open' },
  VALIDATED: { color: palette.success.main, label: 'Validated' },
};
