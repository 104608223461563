import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';

import { paySettlementFormSchema } from '../helpers/formSchemas/formSchemas';
import { PAY_SETTLEMENT_FORM_DEFAULT_VALUES } from '../helpers/formSchemas/formDefaultValues';
import { numberToUSDCurrency } from '../helpers/numberToUSDCurrency';

import FormCheckboxField from './formFields/FormCheckboxField';
import FormDateField from './formFields/FormDateField';
import PinInput from './PinInput';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: '802px',
      boxSizing: 'border-box',
      padding: '32px',
      gap: '32px',
      border: `1px solid ${theme.palette.secondary.dark}`,
      borderRadius: '16px',
      backgroundColor: theme.palette.primary.light,
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.common.white,
    },
  },
  dialogTitle: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '34px',
    lineHeight: '40.8px',
    padding: '32px 0px 0px 0px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '20px',
  },
  dialogContentText: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '24px',
  },
  text: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16.8px',
    color: theme.palette.text.disabled,
  },
  pinInputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px',
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '0px',

    '@media (max-width: 550px)': {
      flexDirection: 'column',
      gap: '16px',

      '@media (max-width: 550px)': {
        paddingRight: '16px',
      },
    },
  },
  defaultButtonStyle: {
    height: '48px',
    maxWidth: '416px',
    width: '100%',
    borderRadius: '12px',
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19.2px',
    textTransform: 'none',
    color: theme.palette.common.white,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.08)',
      background: 'rgba(255, 255, 255, 0.08)',
    },

    '@media (max-width: 550px)': {
      maxWidth: '100%',
    },
  },
  submitButton: {
    background: theme.palette.info.main,

    '&:hover': {
      background: theme.palette.info.main,
    },
  },
  cancelButton: {
    background: theme.palette.info.dark,

    '&:hover': {
      background: theme.palette.info.dark,
    },
  },
}));

enum Step {
  FillForm,
  ReviewSummary,
  Verification,
}

interface HoldPeriodData {
  hold: boolean;
  holdUntil: Date | null | undefined;
  numbers: Array<{
    value: string;
  }>;
}

interface PaySettlementPopupProps {
  isOpen: boolean;
  settledAmount: string;
  onSubmit: (values: { pin: string; holdUntil: string | null }) => void;
  onCancel: () => void;
}

const PaySettlementPopup = ({
  isOpen,
  settledAmount,
  onSubmit: onCustomSubmit,
  onCancel,
}: PaySettlementPopupProps) => {
  const { classes } = useStyles();

  const [step, setStep] = useState<Step>(Step.FillForm);

  const stepFields: Record<Step, (keyof HoldPeriodData)[]> = {
    [Step.FillForm]: ['hold', 'holdUntil'],
    [Step.ReviewSummary]: [],
    [Step.Verification]: ['numbers'],
  };

  const formMethods = useForm<HoldPeriodData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(paySettlementFormSchema),
    defaultValues: PAY_SETTLEMENT_FORM_DEFAULT_VALUES,
  });

  const { handleSubmit, reset, trigger, watch } = formMethods;

  const hold = watch('hold');
  const holdUntil = watch('holdUntil');

  const onSubmit = (values: HoldPeriodData) => {
    const pin = values.numbers.map((num) => num.value).join('');

    onCustomSubmit({ pin, holdUntil: moment(values.holdUntil).toISOString() });
  };

  const handleCancel = () => {
    onCancel();
  };

  const resetModalState = () => {
    setStep(Step.FillForm);
    reset(PAY_SETTLEMENT_FORM_DEFAULT_VALUES);
  };

  const getMainButtonTitle = () => {
    if (step === Step.Verification) {
      return 'Submit';
    }

    return 'Next';
  };

  const getMainButtonHandler = () => {
    switch (step) {
      case Step.FillForm:
        return handleNext;
      case Step.ReviewSummary:
        return handleNext;
      case Step.Verification:
        return handleSubmit(onSubmit);
    }
  };

  const getTitle = () => {
    if (step === Step.Verification) {
      return 'Verification';
    }

    return 'Pay Settlement';
  };

  const handleNext = async () => {
    const currentStepFields = stepFields[step];
    const isValid = await trigger(currentStepFields);
    if (isValid) {
      setStep((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (isOpen) {
      resetModalState();
    }
  }, [isOpen]);

  return (
    <Dialog className={classes.dialogContainer} open={isOpen} onClose={handleCancel}>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={(theme) => ({
          position: 'absolute',
          top: 32,
          right: 32,
          color: theme.palette.common.white,
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle className={classes.dialogTitle}>{getTitle()}</DialogTitle>
      <DialogContent sx={{ padding: '0px' }}>
        <FormProvider {...formMethods}>
          <form className={classes.form}>
            {step === Step.FillForm && (
              <>
                <DialogContentText classes={classes.dialogContentText}>
                  The date of the hold period can be customized. If no date is selected, the funds
                  will be released immediately after the end of the standard hold period.
                </DialogContentText>
                <FormCheckboxField
                  name="hold"
                  label="Select the date until the funds will be held"
                />
                {hold && <FormDateField name="holdUntil" label="Select Date" withTime />}
              </>
            )}
            {step === Step.ReviewSummary && (
              <>
                <DialogContentText classes={classes.dialogContentText}>
                  Note: Participating firms’ compensation will no longer be editable once this
                  settlement transaction is submitted.
                </DialogContentText>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <DialogContentText classes={classes.dialogContentText}>
                    Settled Amount:
                  </DialogContentText>
                  <DialogContentText classes={classes.dialogContentText}>
                    {numberToUSDCurrency(settledAmount)}
                  </DialogContentText>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <DialogContentText classes={classes.dialogContentText}>
                    Hold Until:
                  </DialogContentText>
                  {
                    <DialogContentText classes={classes.dialogContentText}>
                      {holdUntil ? moment(holdUntil).format('M/D/YYYY') : '-'}
                    </DialogContentText>
                  }
                </Box>
              </>
            )}
            {step === Step.Verification && (
              <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
                <DialogContentText classes={classes.dialogContentText}>
                  Enter 4-digit PIN
                </DialogContentText>
                <PinInput name="numbers" />
              </Box>
            )}
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions className={classes.dialogActions} disableSpacing>
        <Button
          className={clsx(classes.defaultButtonStyle, classes.submitButton)}
          onClick={getMainButtonHandler()}
        >
          {getMainButtonTitle()}
        </Button>
        <Button
          className={clsx(classes.defaultButtonStyle, classes.cancelButton)}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaySettlementPopup;
