import { palette } from '../../../palette';
import {
  FundingStatus,
  NetSettlementStatus,
  AttorneysFeeStatus,
  DeductionStatus,
  StatusMap,
} from '../../../types';

export const FundingStatusMap: StatusMap<FundingStatus> = {
  NotStarted: { color: palette.warning.main, label: 'Not Started' },
  Initializing: { color: palette.warning.main, label: 'Initializing' },
  Processing: { color: palette.warning.main, label: 'Processing' },
  OnHold: { color: palette.warning.main, label: 'On Hold' },
  Error: { color: palette.error.main, label: 'Error' },
  CriticalError: { color: palette.error.main, label: 'Critical Error' },
  Completed: { color: palette.success.main, label: 'Completed' },
};

export const NetSettlementStatusMap: StatusMap<NetSettlementStatus> = {
  Unpaid: { color: palette.warning.main, label: 'Unpaid' },
  PaymentInProgress: { color: palette.warning.main, label: 'Payment In Progress' },
  Error: { color: palette.error.main, label: 'Error' },
  CriticalError: { color: palette.error.main, label: 'Critical Error' },
  Paid: { color: palette.success.main, label: 'Paid' },
};

export const AttorneysFeeStatusMap: StatusMap<AttorneysFeeStatus> = {
  Unpaid: { color: palette.warning.main, label: 'Unpaid' },
  PaymentInProgress: { color: palette.warning.main, label: 'Payment In Progress' },
  Error: { color: palette.error.main, label: 'Error' },
  CriticalError: { color: palette.error.main, label: 'Critical Error' },
  Paid: { color: palette.success.main, label: 'Paid' },
};

export const DeductionStatusMap: StatusMap<DeductionStatus> = {
  Unpaid: { color: palette.warning.main, label: 'Unpaid' },
  PaymentInProgress: { color: palette.warning.main, label: 'Payment In Progress' },
  Error: { color: palette.error.main, label: 'Error' },
  CriticalError: { color: palette.error.main, label: 'Critical Error' },
  Paid: { color: palette.success.main, label: 'Paid' },
};
