import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { getSettlementTimeline } from '../../../api/paymentsApi/settlementsApi';
import Timeline from '../../../components/Timeline';
import { TimelineEvent } from '../../../types';

const SettlementsTimeline = () => {
  const { settlementId } = useParams<string>();
  const [settlementTimeline, setSettlementTimeline] = useState<TimelineEvent[]>([]);

  useEffect(() => {
    if (!settlementId) {
      return;
    }

    getSettlementTimeline(Number(settlementId)).then((res) => {
      if (res.data) {
        const mappedTimeline = res.data.map((el) => ({
          status: el.statusReadable,
          date: el.date,
        }));

        setSettlementTimeline(mappedTimeline);
      }
    });
  }, []);

  return <Timeline data={settlementTimeline} />;
};

export default SettlementsTimeline;
