import { useCallback, useEffect, useReducer, useState } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { deleteTrustAccount, getTrustAccounts } from '../../../api/firmsApi/trustAccountsApi';
import Loader from '../../../components/Loader';
import NoDataText from '../../../components/NoDataText';
import Popup from '../../../components/Popup';
import TableContainer from '../../../components/Table/TableContainer';
import { POPUP_DESCRIPTIONS } from '../../../constants/popupDescriptions';
import { FIRM_BANKING_TRUST_ACCOUNTS_LIST_TABLE_LABELS } from '../../../constants/tableLabels';
import { handleSortTableData } from '../../../helpers/handleSortTableData';
import useSnackBar from '../../../hooks/useSnackBar';
import usePagination from '../../../hooks/usePagination';
import { ActiveSortField, TrustAccountShort } from '../../../types';
import FirmBankingAddAccountButton from '../FirmBankingAddAccountButton';
import { MenuOption } from '../types';

import FirmTrustAccountTableRow from './FirmTrustAccountTableRow';
import FirmTrustAccountPopup, { Mode } from './FirmTrustAccountPopup';
import FirmTrustAccountDetailsPopup from './FirmTrustAccountDetailsPopup';
import { initialState, reducer } from './reducer';

const useStyles = makeStyles()((theme: Theme) => ({
  systemAccountsLabel: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '120%',
    color: theme.palette.common.white,
    fontFamily: 'Poppins',
  },
  tableHeadline: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',

    '@media (max-width: 800px)': {
      flexDirection: 'column',
      maxHeight: 'auto',
      gap: '12px',
    },
  },
}));

// TODO: implement a common component for trust & unrestricted accounts Views, etc.
const FirmTrustAccountsView = () => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();
  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);

  const sortableFields = {
    createdAt: {
      order: '',
      fieldName: 'createdAt',
    },
  };

  const fetchTrustAccounts = useCallback(() => {
    dispatch({ type: 'SET_IS_LOADING', isLoading: true });

    getTrustAccounts({
      page: pageNumber,
      size: rowsPerPage,
      count: pageNumber === 1,
      activeSortField,
    })
      .then(({ data }) => {
        if (data.totalCount !== null) {
          setTotalCount && setTotalCount(data.totalCount);
        }

        dispatch({ type: 'SET_TRUST_ACCOUNTS', trustAccounts: data.resultSet });
      })
      .catch((error) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Cannot get trust accounts.',
          type: 'error',
        }));
      })
      .finally(() => {
        dispatch({ type: 'SET_IS_LOADING', isLoading: false });
      });
  }, [pageNumber, rowsPerPage, activeSortField]);

  const handleDeleteTrustAccount = () => {
    if (!state.selectedTrustAccount) {
      return;
    }

    dispatch({ type: 'CLOSE_POPUP' });

    deleteTrustAccount(state.selectedTrustAccount.id)
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'Trust account deleted successfully.',
          type: 'success',
        }));
        fetchTrustAccounts();
      })
      .catch((error) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message,
          type: 'error',
        }));
      });
  };

  const handleSuccess = () => {
    handleReset();
    fetchTrustAccounts();
  };

  const handleReset = () => {
    dispatch({ type: 'CLOSE_POPUP' });
  };

  const handleOptionClick = (accountId: number, option: MenuOption) => {
    const account = state.trustAccounts.find((el) => el.id === accountId) as TrustAccountShort;

    switch (option) {
      case MenuOption.Details:
        dispatch({ type: 'OPEN_DETAILS_POPUP', trustAccount: account });
        break;
      case MenuOption.Edit:
        dispatch({
          type: 'OPEN_TRUST_ACCOUNT_POPUP',
          mode: Mode.Edit,
          trustAccount: account,
        });
        break;
    }
  };

  const handleDeleteClick = (accountId: number) => {
    const account = state.trustAccounts.find((el) => el.id === accountId) as TrustAccountShort;

    dispatch({ type: 'OPEN_DELETE_POPUP', trustAccount: account });
  };

  useEffect(() => {
    fetchTrustAccounts();
  }, [activeSortField, pageNumber, rowsPerPage]);

  return (
    <Box mt="40px">
      <Box className={classes.tableHeadline}>
        <Typography className={classes.systemAccountsLabel}>Trust Accounts</Typography>
        <FirmBankingAddAccountButton
          buttonText="Add Trust Account"
          handleClick={() => dispatch({ type: 'OPEN_TRUST_ACCOUNT_POPUP', mode: Mode.Create })}
        />
      </Box>

      {state.isLoading ? (
        <Loader colorType="warning"></Loader>
      ) : (
        <>
          {state.trustAccounts.length === 0 ? (
            <NoDataText />
          ) : (
            <TableContainer
              headerLabels={FIRM_BANKING_TRUST_ACCOUNTS_LIST_TABLE_LABELS}
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
              hasBigData={state.trustAccounts.length > 3}
              handleSortTableData={handleSortTableData}
            >
              <FirmTrustAccountTableRow
                data={state.trustAccounts}
                onEditButtonClick={handleOptionClick}
                onDeleteButtonClick={handleDeleteClick}
              />
            </TableContainer>
          )}

          <FirmTrustAccountPopup
            isOpen={state.trustAccountPopup.isOpen}
            mode={state.trustAccountPopup.mode}
            data={state.selectedTrustAccount}
            onSuccess={handleSuccess}
            onCancel={handleReset}
          />

          <FirmTrustAccountDetailsPopup
            isOpen={state.isDetailsPopupOpen}
            accountId={state.selectedTrustAccount?.id as number}
            onClose={handleReset}
          />

          <Popup
            isOpen={state.isDeletePopupOpen}
            headlineText={POPUP_DESCRIPTIONS.trustAccountDelete.headlineText}
            contentText={POPUP_DESCRIPTIONS.trustAccountDelete.contentText}
            onMainButtonClick={handleDeleteTrustAccount}
            onSecondaryButtonClick={handleReset}
          />
        </>
      )}
    </Box>
  );
};

export default FirmTrustAccountsView;
