import { ActiveSortField } from '../types';

export const parseQuerySortFields = (sortField?: ActiveSortField | null) => {
  let resultQuerySortFieldsString = '';

  if (sortField) {
    resultQuerySortFieldsString += `&order[${sortField.fieldName}]=${sortField.order}`;
  }

  return resultQuerySortFieldsString;
};
