import { Dispatch, SetStateAction, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { SYSTEM_ACCOUNT_LABELS } from '../types';

import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import { FIRM_WITHDRAW_FUNDS_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';

const useStyles = makeStyles<{ isValid?: boolean }>()((theme: Theme, { isValid }) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
      maxWidth: '800px',
      width: '100%',
      padding: '32px',
      boxSizing: 'border-box',
      borderRadius: '16px',
    },

    '& .MuiDialogTitle-root': {
      padding: 0,
      fontSize: '34px',
      fontWeight: 600,
      lineHeight: '120%',
      color: theme.palette.common.white,
      fontFamily: 'Poppins',
    },

    '& .MuiDialogContent-root': {
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      overflowY: 'clip',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  popupActions: {
    display: 'flex',
    width: '100%',
    margin: '0 auto',
    gap: '15px',
    padding: 0,
  },
  popupButton: {
    color: theme.palette.info.main,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&:hover': {
      background: 'rgba(144, 202, 249, 0.08)',
    },
  },
  dialogContainer: {
    display: 'flex',
    gap: '15px',

    '@media (max-width: 600px)': {
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
    },
  },
  dialogContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '416px',
    width: '100%',
    justifyContent: 'center',
    ...(!isValid && {
      margin: '0 auto',
    }),
  },
  warningTextContainer: {
    maxWidth: '154px',
    width: '100%',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: '112px',
    borderRadius: '12px',
    border: `1px solid ${theme.palette.error.main}`,
    boxSizing: 'border-box',
    marginTop: 'auto',
    height: '112px',

    '@media (max-width: 600px)': {
      height: 'auto',
      maxWidth: '100%',
    },
  },
  warningText: {
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '120%',
    color: theme.palette.error.main,
    textAlign: 'center',
  },
}));

interface FirmBankingWithdrawPopupProps {
  isOpen: boolean;
  handleClosePopup: () => void;
  setSelectedAccountType: Dispatch<SetStateAction<string>>;
  selectedAccountType: string;
  children: JSX.Element;
  handleOpenVerificationPopup: () => void;
  isFormSubmitting: boolean;
  headlineText: string;
}

const FirmBankingWithdrawPopup = ({
  isOpen,
  handleClosePopup,
  setSelectedAccountType,
  selectedAccountType,
  children,
  handleOpenVerificationPopup,
  isFormSubmitting,
  headlineText,
}: FirmBankingWithdrawPopupProps) => {
  const {
    reset,
    formState: { isDirty, isValid },
  } = useFormContext();

  const { classes } = useStyles({ isValid });

  const onPopupClose = () => {
    handleClosePopup();
    setSelectedAccountType('');
    reset(FIRM_WITHDRAW_FUNDS_FORM_DEFAULT_VALUES);
  };

  useEffect(() => {
    reset({
      fromSystemAccountType:
        SYSTEM_ACCOUNT_LABELS[selectedAccountType as keyof typeof SYSTEM_ACCOUNT_LABELS],
      toUnrestrictedAccountId: '',
      amount: '',
    });
  }, [selectedAccountType]);

  return (
    <Dialog className={classes.popupContainer} open={isOpen} onClose={onPopupClose}>
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          aria-label="close"
          onClick={onPopupClose}
          disableRipple
          sx={(theme) => ({
            color: theme.palette.common.white,
          })}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle marginBottom="32px">{headlineText}</DialogTitle>
      <Box className={classes.dialogContainer}>
        {isValid && (
          <Box className={classes.warningTextContainer}>
            <Typography className={classes.warningText}>
              Are you sure you want to perform this transaction?
            </Typography>
          </Box>
        )}

        <Box className={classes.dialogContentWrapper}>
          <DialogContent>{children}</DialogContent>
          <DialogActions className={classes.popupActions}>
            <Box display="flex" flexDirection="column" gap="16px" width="100%" marginTop="32px">
              <FormSubmitButton
                disabled={!isValid || !isDirty || isFormSubmitting}
                buttonText="Next"
                onClick={handleOpenVerificationPopup}
              />
              <FormCancelButton onClick={onPopupClose} />
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
};

export default FirmBankingWithdrawPopup;
