export const POPUP_DESCRIPTIONS = {
  adminsPage: {
    headlineText: 'Reset account access',
    contentText: 'A new temporary password will be sent to the admin user’s email address on file.',
  },
  lawyersPage: {
    headlineText: 'Reset account access',
    contentText:
      'A new temporary password will be sent to the lawyer user’s email address on file.',
  },
  codesTab: {
    headlineText: 'Are you sure you want to delete this code?',
    contentText: 'This code will be permanently deleted',
  },
  categoriesTab: {
    headlineText: 'Are you sure you want to delete this category?',
    contentText: 'This category will be permanently deleted',
  },
  createDataRequestPage: {
    headlineText: 'Add custom questions',
    contentText: 'Save this data request then edit the saved draft to add custom questions.',
  },
  caseAbandon: {
    headlineText: 'Abandon case',
    contentText: 'Are you sure you want to abandon and archive this case?',
  },
  deductionDelete: {
    headlineText: 'Are you sure you want to delete this deduction?',
    contentText: 'This will affect all data contain this deduction',
  },
  settlementDelete: {
    headlineText: 'Are you sure you want to delete this settlement?',
    contentText: 'This will affect all data contain this settlement',
  },
  caseTypeDelete: {
    headlineText: 'Are you sure you want to delete this case type?',
    contentText: 'This case type will be permanently deleted.',
  },
  questionnaireDelete: {
    headlineText: 'Are you sure you want to delete this questionnaire?',
    contentText: 'This questionnaire will be permanently deleted.',
  },
  formBuilder: {
    headlineText: 'Conditional question.',
    contentText: 'You cannot establish the dependence of a question on itself.',
  },
  formSnippetDelete: {
    headlineText: 'Are you sure you want to delete this snippet?',
    contentText: 'This snippet will be permanently deleted.',
  },
  documentRequestCancel: {
    headlineText: 'Cancel Document Request',
    contentText:
      'Are you sure you want to cancel this Document Request? This action cannot be undone.',
  },
  signatureRequestCancel: {
    headlineText: 'Cancel Signature Request',
    contentText:
      'Are you sure you want to cancel this Signature Request? This action cannot be undone.',
  },
  dataRequestCancel: {
    headlineText: 'Cancel Data Request',
    contentText: 'Are you sure you want to cancel this Data Request? This action cannot be undone.',
  },
  documentRequestDelete: {
    headlineText: 'Delete Document Request',
    contentText:
      'Are you sure you want to delete this Document Request? This action cannot be undone.',
  },
  dataRequestDelete: {
    headlineText: 'Delete Data Request',
    contentText: 'Are you sure you want to delete this Data Request? This action cannot be undone.',
  },
  cancelSettlementPay: {
    headlineText: 'Cancel Settlement',
    contentText: 'Are you sure you want to cancel settlement?',
  },
  sessionExpired: {
    headlineText: 'Session Expired',
    contentText: 'Your session is about to expire. You will be logged out in 1 min.',
  },
  trustAccountDelete: {
    headlineText: 'Are you sure you want to delete this account?',
    contentText: 'This account will no longer be available for transactions.',
  },
  unrestrictedAccountDelete: {
    headlineText: 'Are you sure you want to delete this account?',
    contentText: 'This account will no longer be available for transactions.',
  },
};
