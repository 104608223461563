import api from '../service/api.service';

import {
  ActiveSortField,
  PaginationQueryParams,
  ResultSet,
  Settlement,
  SettlementPaymentDetails,
  SettlementShort,
  SettlementsPayoutCheckData,
  SettlementTimeline,
} from '../../types';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL = 'v1/fo/settlements';

interface CreateSettlementBody {
  caseId: number;
  defendantId: number;
  settledAmount: number;
}

interface PaySettlementBody {
  includedDeductionIds?: number[];
  holdUntil?: string;
}

export const getSettlementTimeline = async (id: number) => {
  const targetUrl = `${BASE_URL}/${id}/timeline`;

  return await api.get<SettlementTimeline[]>(targetUrl);
};

export const getSettlementRecord = async (id: string | number) => {
  const targetUrl = `${BASE_URL}/${id}`;

  return await api.get<Settlement>(targetUrl);
};

export const deleteSettlementRecord = async (id: string | number) => {
  const targetUrl = `${BASE_URL}/${id}`;

  return await api.delete<void>(targetUrl);
};

export const getSettlementsList = async ({
  size,
  page,
  count,
  match,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL}?size=${size}&page=${page}&count=${count}&match=${match || ''}${
    queryFilters || ''
  }${sortFilters}`;

  return await api.get<ResultSet<SettlementShort>>(targetUrl);
};

export const createSettlementRecord = async (data: CreateSettlementBody) => {
  return await api.post<Settlement>(BASE_URL, data);
};

export const exportSettlements = async ({
  size,
  page,
  count,
  match,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL}/export?size=${size}&page=${page}&count=${count}&match=${
    match || ''
  }${queryFilters || ''}${sortFilters}`;

  return await api.post<string>(targetUrl);
};

export const getSettlementsCheck = async (
  settlementId: string | number,
  includedDeductionIds?: number[],
) => {
  const targetUrl = `${BASE_URL}/${settlementId}/payment-preview`;

  return await api.get<SettlementsPayoutCheckData>(targetUrl, {
    params: {
      ...(includedDeductionIds && { includedDeductionIds }),
    },
  });
};

export const getSettlementDetails = async (id: string | number) => {
  const targetUrl = `${BASE_URL}/${id}/payment-details`;

  return await api.get<SettlementPaymentDetails>(targetUrl);
};

export const paySettlement = async (
  settlementId: string | number,
  pin: string,
  body?: PaySettlementBody,
) => {
  const targetUrl = `${BASE_URL}/${settlementId}/pay`;

  return await api.put<void>(targetUrl, body, {
    headers: { 'x-pin': pin },
  });
};

export const releaseSettlement = async (id: string | number, pin: string) => {
  const targetUrl = `${BASE_URL}/${id}/release`;

  return await api.put<void>(targetUrl, null, {
    headers: { 'x-pin': pin },
  });
};

export const retrySettlementPayment = async (id: string | number, pin: string) => {
  const targetUrl = `${BASE_URL}/${id}/retry`;

  return await api.put<void>(targetUrl, null, {
    headers: { 'x-pin': pin },
  });
};
