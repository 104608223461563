import api from '../service/api.service';

import {
  ActiveSortField,
  DataRequestData,
  DataRequestShortData,
  DataRequestTimeline,
  PaginationQueryParams,
  QuestionnaireForm,
} from '../../types';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL_V2 = '/v2/data-requests';
const BASE_URL_V3 = '/v3/data-requests';
const BASE_URL_V5 = '/v5/data-requests';

export const getDataRequestTimeline = async (id: number) => {
  const targetUrl = `${BASE_URL_V3}/${id}/timeline`;

  return await api.get<DataRequestTimeline[]>(targetUrl);
};

export const getDataRequests = async ({
  size,
  page,
  count,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL_V5}?size=${size}&page=${page}&count=${count}${
    queryFilters || ''
  }${sortFilters}`;

  return await api.get<{ resultSet: Array<DataRequestShortData>; totalCount: number | null }>(
    targetUrl,
  );
};

export const getDataRequest = async (id: string) => {
  const targetUrl = `${BASE_URL_V5}/${id}`;

  return await api.get<DataRequestData>(targetUrl);
};

export const updateDataRequest = async (
  id: string,
  data: { form?: QuestionnaireForm | null; name?: string; notes?: string; message?: string },
) => {
  const targetUrl = `${BASE_URL_V5}/${id}`;

  return await api.put<DataRequestData>(targetUrl, data);
};

export const createDataRequest = async (data: {
  form: QuestionnaireForm | null;
  caseId: number;
  name: string;
  notes?: string;
  message?: string;
}) => {
  return await api.post<DataRequestData>(BASE_URL_V5, data);
};

export const sendDataRequest = async (id: string) => {
  return await api.post<DataRequestData>(`${BASE_URL_V5}/${id}/send`);
};

export const cancelDataRequest = async (id: string) => {
  const targetUrl = `${BASE_URL_V5}/${id}/cancel`;

  return await api.post<DataRequestData>(targetUrl);
};

export const downloadDataRequestAnswers = async (id: string) => {
  const targetUrl = `${BASE_URL_V2}/${id}/answers/csv`;

  return await api.get<string>(targetUrl);
};

export const deleteDataRequest = async (id: string) => {
  const targetUrl = `${BASE_URL_V2}/${id}`;

  return await api.delete(targetUrl);
};
