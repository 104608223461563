import { TableCell, TableRow } from '@mui/material';

import { formatDate } from '../../../helpers/formatDate';
import { numberToUSDCurrency } from '../../../helpers/numberToUSDCurrency';
import { FirmTransaction } from '../../../types';

interface FirmTransactionsTableDataRowProps {
  tableData: FirmTransaction[];
}

const FirmTransactionsTableDataRow = ({ tableData }: FirmTransactionsTableDataRowProps) => {
  const formattedList = tableData.map((transaction) => ({
    lastUpdatedOn: formatDate(transaction.lastUpdatedOn),
    accountId: transaction.accountId,
    transactionId: transaction.transactionId,
    scheduleClass: transaction.scheduleClass,
    memo: transaction.memo,
    type: transaction.type,
    amount: numberToUSDCurrency(transaction.amount),
    runningBalance: numberToUSDCurrency(transaction.runningBalance),
  }));

  return (
    <>
      {formattedList.map((tableItem) => (
        <TableRow key={tableItem.transactionId}>
          {Object.values(tableItem).map((item, idx) => (
            <TableCell key={`${tableItem.transactionId}_${idx}`}>{item}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default FirmTransactionsTableDataRow;
