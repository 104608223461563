import { useEffect } from 'react';
import { Navigate } from 'react-router';
import useAuth from '../hooks/useAuth';

// TODO: auth logic
const NoMatchRoute = () => {
  const { checkAuth, loading, isAuth } = useAuth();

  useEffect(() => {
    checkAuth();
  }, []);

  if (loading) return null;
  if (isAuth) return <Navigate to="/cases" replace />;
  return <Navigate to="/" replace />;
};

export default NoMatchRoute;
