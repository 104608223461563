import { UnrestrictedAccountShort } from '../../../types';

import { Mode } from './FirmUnrestrictedAccountPopup';

interface UnrestrictedAccountPopup {
  isOpen: boolean;
  mode: Mode;
}

interface UnrestrictedAccountsViewState {
  isLoading: boolean;
  unrestrictedAccounts: UnrestrictedAccountShort[];
  selectedUnrestrictedAccount: UnrestrictedAccountShort | null;
  unrestrictedAccountPopup: UnrestrictedAccountPopup;
  isDetailsPopupOpen: boolean;
  isDeletePopupOpen: boolean;
}

type Action =
  | { type: 'SET_IS_LOADING'; isLoading: boolean }
  | { type: 'SET_UNRESTRICTED_ACCOUNTS'; unrestrictedAccounts: UnrestrictedAccountShort[] }
  | {
      type: 'SET_SELECTED_UNRESTRICTED_ACCOUNT';
      unrestrictedAccount?: UnrestrictedAccountShort | null;
    }
  | {
      type: 'OPEN_UNRESTRICTED_ACCOUNT_POPUP';
      mode: Mode;
      unrestrictedAccount?: UnrestrictedAccountShort | null;
    }
  | { type: 'OPEN_DETAILS_POPUP'; unrestrictedAccount: UnrestrictedAccountShort }
  | { type: 'OPEN_DELETE_POPUP'; unrestrictedAccount: UnrestrictedAccountShort }
  | { type: 'CLOSE_POPUP' };

export const initialState: UnrestrictedAccountsViewState = {
  isLoading: false,
  unrestrictedAccounts: [],
  selectedUnrestrictedAccount: null,
  unrestrictedAccountPopup: { isOpen: false, mode: Mode.Create },
  isDetailsPopupOpen: false,
  isDeletePopupOpen: false,
};

export const reducer = (
  state: UnrestrictedAccountsViewState,
  action: Action,
): UnrestrictedAccountsViewState => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.isLoading };
    case 'SET_UNRESTRICTED_ACCOUNTS':
      return { ...state, unrestrictedAccounts: action.unrestrictedAccounts };
    case 'SET_SELECTED_UNRESTRICTED_ACCOUNT':
      return { ...state, selectedUnrestrictedAccount: action.unrestrictedAccount ?? null };
    case 'OPEN_UNRESTRICTED_ACCOUNT_POPUP':
      return {
        ...state,
        unrestrictedAccountPopup: { isOpen: true, mode: action.mode },
        selectedUnrestrictedAccount: action.unrestrictedAccount ?? null,
      };
    case 'OPEN_DETAILS_POPUP':
      return {
        ...state,
        isDetailsPopupOpen: true,
        selectedUnrestrictedAccount: action.unrestrictedAccount,
      };
    case 'OPEN_DELETE_POPUP':
      return {
        ...state,
        isDeletePopupOpen: true,
        selectedUnrestrictedAccount: action.unrestrictedAccount,
      };
    case 'CLOSE_POPUP':
      return {
        ...state,
        unrestrictedAccountPopup: { isOpen: false, mode: Mode.Create },
        isDetailsPopupOpen: false,
        isDeletePopupOpen: false,
        selectedUnrestrictedAccount: null,
      };
    default:
      return state;
  }
};
