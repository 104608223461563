import { TrustAccountStatus, UnrestrictedAccountStatus } from '../../types';

export interface FirmBankingSystemAccountData {
  accountNumber: string;
  routingNumber: string;
  balance: string;
  status: string;
  withdrawalAvailable: boolean;
}

export type IntegrationProvisioningData = {
  ofac?: {
    status: string;
    reason: string | null;
  };
  provisioning: {
    error?: {
      status: string;
      reason: string | null;
    };
    status: keyof typeof OFAC_STATUSES | keyof typeof INTEGRATION_STATUS;
  };
};

export interface FirmBankingData {
  accounts: {
    exp: FirmBankingSystemAccountData | null;
    lien: FirmBankingSystemAccountData | null;
    rev: FirmBankingSystemAccountData | null;
  };
  canStartIntegration: boolean;
}

export enum INTEGRATION_STATUS {
  InProgress = 'In Progress',
  Error = 'Error',
  CriticalError = 'Critical Error',
  Completed = 'Completed',
}

export enum OFAC_STATUSES {
  PENDING_VERIFICATION = 'Pending Verification',
  VERIFIED = 'Verified',
  UNDER_REVIEW = 'Under Review',
  REJECTED = 'Rejected',
  IGNORED = 'Ignored',
}

export enum SYSTEM_ACCOUNT_LABELS {
  rev = 'Revenue Recognition',
  exp = 'Expense Reimbursement',
  lien = 'Lien Resolution',
}

export enum SYSTEM_ACCOUNT_STATUSES {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  CLOSURE_INITIATED = 'CLOSURE_INITIATED',
  CLOSED = 'CLOSED',
}

export interface UnrestrictedAccountSubmitFormProps {
  name: string;
  type: string;
  accountNumber: string;
  routingNumber: string;
}

export interface UnrestrictedAccountTableItem {
  createdAt: string;
  id: number;
  name: string;
  type: string;
  accountNumber: string;
  routingNumber: string;
  isEwsVerified: string;
  status: UnrestrictedAccountStatus;
  canBeDeleted: boolean;
}

export interface TrustAccountSubmitFormProps {
  name: string;
  type: string;
  accountNumber: string;
  routingNumber: string;
}

export interface TrustAccountTableItem {
  createdAt: string;
  id: number;
  name: string;
  type: string;
  accountNumber: string;
  routingNumber: string;
  isEwsVerified: string;
  status: TrustAccountStatus;
  canBeDeleted: boolean;
}

export interface FirmBankingWithdrawFormSubmitProps {
  fromSystemAccountType: string;
  toUnrestrictedAccountId: string;
  amount: string;
}

export enum AccountTypeShortCuts {
  'Revenue Recognition' = 'REV',
  'Expense Reimbursement' = 'EXP',
  'Lien Resolution' = 'LIEN',
}

export enum MenuOption {
  Details = 'See Details',
  Edit = 'Edit',
}
