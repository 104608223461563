import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { getSignatureTimeline } from '../../../api/requestsApi/signaturesApi';
import Timeline from '../../../components/Timeline';
import { TimelineEvent } from '../../../types';

const SignaturesTimeline = () => {
  const { signatureId } = useParams<string>();
  const [signatureTimeline, setSignatureTimeline] = useState<TimelineEvent[]>([]);

  useEffect(() => {
    if (!signatureId) {
      return;
    }

    getSignatureTimeline(Number(signatureId)).then((res) => {
      if (res.data) {
        setSignatureTimeline(res.data);
      }
    });
  }, []);

  return <Timeline data={signatureTimeline} />;
};

export default SignaturesTimeline;
