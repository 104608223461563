import { CaseTypeCategories } from './constants/constants';

export interface Client {
  id: number;
  firstName: string;
  middleName: string | null;
  lastName: string;
}

export interface FilterTypes {
  firmId?: number | string;
  referenceId?: string;
  clientId?: number | string;
  defendantId?: number | string;
  categoryId?: number | string;
  caseId?: number | string;
  isArchived?: boolean;
  date?: {
    from: Date;
    to: Date;
  };
  from?: string;
  to?: string;
  status?: string;
  role?: string;
  type?: string;
  caseTypeId?: number | string;
  state?: string;
  category?: string;
  statuses?: [string];
  plaintiffId?: number | string;
}

export type AdminsListData = {
  createdAt: string;
  email: string;
  firstName: string;
  id: number;
  isSuperAdmin: boolean;
  lastName: string;
  phone: string;
  referenceId: string;
  updatedAt: string;
  isEnabled: boolean;
};

export type FirmData = {
  id?: string;
  name: string;
  tin: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  trustAccountNumber: string;
  trustAccountRoutingNumber: string;
  brandColor: string;
  zendeskBrandName: string;
  docusignAccountId: string;
  sendAppInviteTrigger?: string;
  createWalletTrigger?: string;
  issueVirtualCardTrigger?: string;
  issuePhysicalCardTrigger?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  isClientTriggerActive?: boolean;
  logoImageId?: string;
  createdAt?: string;
  isArchived?: string;
};

export type User = {
  cognitoId: string | null;
  role: string;
  createdAt: string;
  updatedAt: string;
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isPinSet: boolean;
  firm: {
    id: string;
    name: string;
  };
};

export type SelectOption = {
  id: string;
  label: string;
};

export interface PaginationQueryParams {
  size: number;
  page: number;
  count?: boolean;
  match?: string;
  filters?: FilterTypes;
  activeSortField?: ActiveSortField | null;
  includeGlobal?: boolean;
}

export type DefendantData = {
  id: number;
  name: string;
  referenceId: string;
  firm: { id: string; name: string };
  createdAt: string;
};

export type ClientData = {
  cognitoId: string | null;
  status: 'Created' | 'Active' | 'Archived' | 'Deactivated';
  countryState: string;
  createdAt: string;
  updatedAt: string;
  deactivatedAt: string | null;
  id: number;
  referenceId: string;
  firm: {
    id: number;
    name: string;
  };
  email: string;
  phone: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  address1: string;
  address2: string | null;
  city: string;
  zip: string;
  emailConfirmed: boolean;
  ssnDobActivationPassed: boolean;
  additonalInfo: {
    activeCasesCount: number;
    grossSettlements: number;
    netSettlements: number;
  };
  ssnConfirmed: boolean;
  dobConfirmed: boolean;
};

export type CaseShortData = {
  status: 'Draft' | 'Active' | 'Closed' | 'Abandoned';
  filedAt: string | null;
  sol: string | null;
  closedAt: string | null;
  abandonedAt: string | null;
  createdAt: string;
  updatedAt: string;
  id: number;
  referenceId: string;
  firm: {
    id: number;
    name: string;
    logoImageId: string | null;
    logoImageUrl: string | null;
  };
  client: Client;
  defendants: Array<{
    id: number;
    name: string;
    grossSettlement: number | null;
  }>;
  name: string;
  isArchived: boolean;
  type: {
    category: CaseTypeCategories;
    id: number;
    name: string;
  };
};

export type CaseData = CaseShortData & {
  secondaryFirms: Array<{
    role: 'CoCounsel' | 'ReferringCounsel';
    id: number;
    name: string;
    logoImageId: string | null;
    logoImageUrl: string | null;
  }>;
  trustAccountId: number | null;
  trustAccount: {
    id: number;
    name: string;
  } | null;
  paymentStarted: boolean;
  canBeAbandoned: boolean;
};

export interface DeductionTimeline {
  status: 'Entered' | 'Paid' | 'Canceled' | 'Unexpected';
  date: string;
}

export type DeductionData = {
  id: number;
  type: string;
  status: string;
  firm: { id: string; name: string };
  case: { id: number; name: string };
  defendant: { id: number; name: string };
  plaintiff: { id: number; firstName: string; middleName: string; lastName: string };
  createdAt: string;
  paidAt: string;
  amount: number;
  updatedAt: string;
};

export enum SettlementStatus {
  Draft = 'Draft',
  Unpaid = 'Unpaid',
  InitiatingFunding = 'InitiatingFunding',
  WaitingFunds = 'WaitingFunds',
  DistributingAttyFees = 'DistributingAttyFees',
  DistributingDeductions = 'DistributingDeductions',
  DistributingNetSettlement = 'DistributingNetSettlement',
  Paid = 'Paid',
  Error = 'Error',
  CriticalError = 'CriticalError',
}

export interface SettlementTimeline {
  status: SettlementStatus;
  date: string;
  statusReadable: string;
}

export interface SettlementShort {
  status: SettlementStatus;
  paidAt: string | null;
  createdAt: string;
  updatedAt: string;
  id: number;
  case: {
    id: number;
    referenceId: string;
    name: string;
  };
  firm: {
    id: number;
    name: string;
  };
  client: Client;
  defendant: {
    id: number;
    name: string;
  };
  settledAmount: number;
  attorneyFeesPaidAmount: number | null;
  deductionsPaidAmount: number | null;
  netSettlementPaidAmount: number | null;
}

export interface Settlement
  extends Omit<
    SettlementShort,
    'attorneyFeesPaidAmount' | 'deductionsPaidAmount' | 'netSettlementPaidAmount'
  > {
  deductions: Array<{
    type: 'Advance' | 'Lien';
    createdAt: string;
    id: number;
    amount: number;
    isAssigned: boolean;
  }> | null;
  secondaryFirms: Array<{
    role: 'CoCounsel' | 'ReferringCounsel';
    id: number;
    name: string;
  }>;
}

export interface SettlementsPayoutCheckData {
  total: number;
  attorneysFees: {
    total: number;
    leadFirm: {
      id: number;
      name: string;
      fee: number;
    };
    secondaryFirms: Array<{
      role: 'CoCounsel' | 'ReferringCounsel';
      id: number;
      name: string;
      fees: {
        fixed: number;
        variable: number;
        total: number;
      };
    }>;
  };
  deductions: {
    advances: number;
    liens: number;
    total: number;
  };
  netSettlement: {
    clientId: number;
    amount: number;
  };
  additionalInfo: SettlementAdditionalInfoData;
}

export interface SettlementAdditionalInfoData {
  percentOfTotalGrossSettlement: number;
  percentOfGrossSettlement: number;
  defendantUnpaidGrossSettlement: number;
}

export enum FundingStatus {
  NotStarted = 'NotStarted',
  Initializing = 'Initializing',
  Processing = 'Processing',
  OnHold = 'OnHold',
  Error = 'Error',
  CriticalError = 'CriticalError',
  Completed = 'Completed',
}

export enum NetSettlementStatus {
  Unpaid = 'Unpaid',
  PaymentInProgress = 'PaymentInProgress',
  Error = 'Error',
  CriticalError = 'CriticalError',
  Paid = 'Paid',
}

export enum AttorneysFeeStatus {
  Unpaid = 'Unpaid',
  PaymentInProgress = 'PaymentInProgress',
  Error = 'Error',
  CriticalError = 'CriticalError',
  Paid = 'Paid',
}

export enum DeductionStatus {
  Unpaid = 'Unpaid',
  PaymentInProgress = 'PaymentInProgress',
  Error = 'Error',
  CriticalError = 'CriticalError',
  Paid = 'Paid',
}

export interface SettlementPaymentDetails {
  error: {
    reason: string | null;
    retryAvailable: boolean;
  } | null;
  settledAmount: number;
  funding: {
    status: FundingStatus;
    holdUntil: string | null;
    caseAccountNumber: string;
    canRelease: boolean;
  };
  distributions: {
    netSettlement: {
      status: NetSettlementStatus;
      amount: number;
    } | null;
    attorneysFees: {
      leadFirm: {
        status: AttorneysFeeStatus;
        id: number;
        name: string;
        amount: number;
      } | null;
      totalAmount: number;
      secondaryFirms: Array<{
        role: 'CoCounsel' | 'ReferringCounsel';
        status: AttorneysFeeStatus;
        id: number;
        name: string;
        amount: number;
      }>;
    };
    deductionsTotalAmount: number;
    deductions: Array<{
      type: 'Advance' | 'Lien';
      status: DeductionStatus;
      id: number;
      amount: number;
    }>;
  };
  cancelAvailable: boolean;
}

export enum DocumentRequestStatus {
  Draft = 'Draft',
  Unopened = 'Unopened',
  Opened = 'Opened',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
  Completed = 'Completed',
}

export interface DocumentRequestTimeline {
  status: DocumentRequestStatus;
  date: string;
}

export type DocumentData = {
  id: number;
  status: string;
  updatedAt: string;
  case: { id: number; name: string; referenceId: string };
  completedAt: string;
  createdAt: string;
  fileId: string;
  firm: { id: string; name: string };
  name: string;
  client: Client;
  sentAt: string;
  fileName: string | null;
};

export enum SignatureRequestStatus {
  Created = 'Created',
  Opened = 'Opened',
  Canceled = 'Canceled',
  CancelInProgress = 'CancelInProgress',
  Rejected = 'Rejected',
  Completed = 'Completed',
}

export interface SignatureRequestTimeline {
  status: SignatureRequestStatus;
  date: string;
}

export type SignatureData = {
  id: number;
  status: string;
  updatedAt: string;
  case: { id: number; name: string; referenceId: string };
  completedAt: string;
  createdAt: string;
  documentId: string;
  firm: { id: string; name: string };
  name: string;
  client: Client;
};

export interface TimelineEvent {
  status: string;
  date: string;
}

export interface SettlementTimeline {
  status: SettlementStatus;
  date: string;
  statusReadable: string;
}

export interface SortableFieldsObject {
  [key: string]: {
    order: string;
    fieldName: string;
  };
}

export interface ActiveSortField {
  order: string;
  fieldName: string;
}

export type EntityFlexibleRecord = { entityData: { [key: string]: unknown } };

export interface ClientTransactionsData {
  accountId: string;
  amount: string;
  lastUpdatedOn: string;
  memo: string;
  method: string;
  runningBalance: string;
  scheduleClass: string;
  transactionId: string;
  type: string;
}

export type TrustAccountShortData = {
  id: number;
  firmId: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
};

export type CaseTypeData = {
  category: CaseTypeCategories;
  id: number;
  name: string;
  firmId: number | null;
  trustAccounts: TrustAccountShortData[];
};

export type ErrorData = {
  error: string;
  message: string;
  statusCode: number;
};

export type QuestionnaireBooleanAnswer = {
  itemId: string;
  format: QuestionnaireItemFormat.Boolean;
  value?: boolean | null;
};

export type QuestionnaireDateAnswer = {
  itemId: string;
  format: QuestionnaireItemFormat.Date;
  value?: string | null;
};

export type QuestionnaireDateRangeAnswer = {
  itemId: string;
  format: QuestionnaireItemFormat.DateRange;
  value?: { from?: string | null; to?: string | null } | null;
};

export type QuestionnaireTextAnswer = {
  itemId: string;
  format: QuestionnaireItemFormat.Text;
  value?: string | null;
};

export type QuestionnaireAnswer =
  | QuestionnaireBooleanAnswer
  | QuestionnaireDateAnswer
  | QuestionnaireDateRangeAnswer
  | QuestionnaireTextAnswer;

export enum DataRequestStatus {
  Draft = 'Draft',
  Unopened = 'Unopened',
  Opened = 'Opened',
  Canceled = 'Canceled',
  Completed = 'Completed',
  Archived = 'Archived',
}

export interface DataRequestTimeline {
  status: DataRequestStatus;
  date: string;
}

export type DataRequestShortData = {
  status: string;
  sentAt: string | null;
  completedAt: string | null;
  id: number;
  name: string;
  case: {
    id: number;
    name: string;
    referenceId: string;
    type: {
      id: number;
      name: string;
    };
  };
  firm: {
    id: number;
    name: string;
  };
  client: Client;
  message: string | null;
  formItemsCount: number;
  formItemAnswersCount: number;
};

export type DataRequestData = DataRequestShortData & {
  form: QuestionnaireForm | null;
  answers: QuestionnaireAnswer[];
  createdAt: string;
  updatedAt: string;
  notes: string | null;
};

export enum QuestionnaireItemFormat {
  Boolean = 'boolean',
  Date = 'date',
  DateRange = 'date_range',
  Text = 'text',
}

type QuestionnaireItemCommonProps = {
  id: string;
  code: string;
  systemName: string;
  questionText: string;
  immutable?: boolean | null;
  dependsOnItem?: {
    booleanItemId: string;
    expectedValue: boolean;
  } | null;
};

export type QuestionnaireBooleanItem = QuestionnaireItemCommonProps & {
  format: QuestionnaireItemFormat.Boolean;
};

export type QuestionnaireDateItem = QuestionnaireItemCommonProps & {
  format: QuestionnaireItemFormat.Date;
};

export type QuestionnaireDateRangeItem = QuestionnaireItemCommonProps & {
  format: QuestionnaireItemFormat.DateRange;
  allowPartial?: boolean | null;
};

export type QuestionnaireTextItem = QuestionnaireItemCommonProps & {
  format: QuestionnaireItemFormat.Text;
  placeholder?: string | null;
};

export type QuestionnaireItem =
  | QuestionnaireBooleanItem
  | QuestionnaireDateItem
  | QuestionnaireDateRangeItem
  | QuestionnaireTextItem;

export type QuestionnaireSection = {
  title: string;
  items: QuestionnaireItem[];
  immutable?: boolean | null;
};

export type QuestionnaireForm = {
  sections: QuestionnaireSection[];
};

export type QuestionnaireShortData = {
  firm: {
    id: number;
    name: string;
  } | null;
  createdAt: string;
  updatedAt: string;
  id: string;
  name: string;
  caseType: {
    id: number;
    name: string;
  };
};

export type QuestionnaireData = QuestionnaireShortData & {
  form: QuestionnaireForm;
};

export type FormSnippetShortData = {
  firm: {
    id: number;
    name: string;
  } | null;
  createdAt: string;
  updatedAt: string;
  id: string;
  name: string;
};

export type FormSnippetData = FormSnippetShortData & {
  data: {
    items: QuestionnaireItem[];
  };
};

export enum TrustAccountStatus {
  Unknown = 'Unknown',
  Created = 'Created',
  Provisioning = 'Provisioning',
  Inactive = 'Inactive',
  PendingVerification = 'PendingVerification',
  Active = 'Active',
  ProvisioningFailed = 'ProvisioningFailed',
  Blocked = 'Blocked',
}

export interface TrustAccountShort {
  type: 'Savings' | 'Checking';
  status: TrustAccountStatus;
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  caseTypes: Array<{
    id: number;
    name: string;
  }>;
  isEwsVerified: boolean;
  canBeDeleted: boolean;
  canUpdatePassportInfo: boolean;
}

export enum ProvisioningStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Error = 'Error',
  CriticalError = 'CriticalError',
  Completed = 'Completed',
}

export enum ExternalAccountStatus {
  Inactive = 'INACTIVE',
  PendingVerification = 'PENDING_VERIFICATION',
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
}

export enum EwsValidationStatus {
  Pending = 'PENDING',
  NotFound = 'NOT_FOUND',
  Closed = 'CLOSED',
  HighRisk = 'HIGH_RISK',
  InvalidDebitAccount = 'INVALID_DEBIT_ACCOUNT',
  Open = 'OPEN',
  Validated = 'VALIDATED',
}

export interface TrustAccount extends Omit<TrustAccountShort, 'isEwsVerified'> {
  integration: {
    provisioning: {
      status: ProvisioningStatus;
      error?: {
        message?: string;
        retryAvailable: boolean;
      };
    };
    canStartProvisioning: boolean;
    externalAccount?: {
      status: ExternalAccountStatus;
      statusReason: string;
      ewsValidation?: {
        status: EwsValidationStatus;
        statusReason: string;
      };
      bank?: {
        name: string;
        routingNumber: string;
      };
    };
  };
}

export enum UnrestrictedAccountStatus {
  Unknown = 'Unknown',
  Created = 'Created',
  Provisioning = 'Provisioning',
  Inactive = 'Inactive',
  PendingVerification = 'PendingVerification',
  Active = 'Active',
  ProvisioningFailed = 'ProvisioningFailed',
  Blocked = 'Blocked',
}

export interface UnrestrictedAccountShort {
  type: 'Savings' | 'Checking';
  status: UnrestrictedAccountStatus;
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isEwsVerified: boolean;
  canBeDeleted: boolean;
  canUpdatePassportInfo: boolean;
}

export interface UnrestrictedAccount extends Omit<UnrestrictedAccountShort, 'isEwsVerified'> {
  integration: {
    provisioning: {
      status: ProvisioningStatus;
      error?: {
        message?: string;
        retryAvailable: boolean;
      };
    };
    canStartProvisioning: boolean;
    externalAccount?: {
      status: ExternalAccountStatus;
      statusReason: string;
      ewsValidation?: {
        status: EwsValidationStatus;
        statusReason: string;
      };
      bank?: {
        name: string;
        routingNumber: string;
      };
    };
  };
}

export interface AttyFeesConfigData {
  case: {
    id: number;
    name: string;
  };
  config: {
    maximumAmount: number | null;
    isDraft: boolean;
    variableFeePercent: number;
    secondaryFirms: Array<{
      role: 'CoCounsel' | 'ReferringCounsel';
      id: number;
      name: string;
      config: {
        fixedFeeAmount: number | null;
        variableFeePercent: number | null;
        variableFeeMaximumAmount: number | null;
      };
    }>;
  };
}

export interface PaymentPreviewData {
  totalGrossSettlement: number;
  attorneysFees: {
    total: number;
    leadFirm: {
      id: number;
      name: string;
      fee: number;
    };
    secondaryFirms: Array<{
      role: 'CoCounsel' | 'ReferringCounsel';
      id: number;
      name: string;
      fees: {
        fixed: number;
        variable: number;
        total: number;
      };
    }>;
  };
  deductions: {
    liens: number;
    advances: number;
    total: number;
  };
  netSettlement: {
    plaintiffId: number;
    amount: number;
  };
}

export interface SaveAttyFeesConfigData {
  case: {
    variableFeePercent?: number | null;
    maximumAmount?: number | null;
  };
  secondaryFirms: Array<{
    fixedAmount?: number | null;
    variableFeePercent?: number | null;
    maximumAmount?: number | null;
    firmId: number;
  }>;
}

export interface ResultSet<Type> {
  resultSet: Type[];
  totalCount: number | null;
}

export interface FirmTransaction {
  type: 'CREDIT' | 'DEBIT';
  scheduleClass: 'SEND' | 'COLLECT';
  method:
    | 'BOOK'
    | 'ACH'
    | 'WIRE'
    | 'CARD'
    | 'CHECK'
    | 'VIRTUAL_CARD'
    | 'INTERNATIONAL_WIRE'
    | 'CASH';
  amount: string;
  runningBalance: string;
  lastUpdatedOn: string;
  transactionId: number;
  accountId: number;
  memo: string;
}

export type StatusMap<T extends string | number | symbol> = Record<
  T,
  { color: string; label: string }
>;
