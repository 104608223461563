import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import FormTextField from '../../../components/formFields/FormTextField';
import FormMaskedTextField from '../../../components/formFields/FormMaskedTextField';
import FormSelectField from '../../../components/formFields/FormSelectField';
import { BANK_ACCOUNT_TYPE_OPTIONS } from '../../../constants/constants';

const useStyles = makeStyles()(() => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    maxWidth: '416px',
    width: '100%',
  },
  formFieldsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    alignItems: 'center',
    padding: '32px 0 32px 0',
  },
  checkboxWrapper: {
    width: '100%',

    '& label': {
      marginRight: 0,
    },
  },
}));

interface FirmTrustAccountFormProps {
  mode: 'create' | 'edit';
  canUpdatePassportInfo?: boolean;
}

const FirmTrustAccountForm = ({ mode, canUpdatePassportInfo }: FirmTrustAccountFormProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.formContainer}>
      <Box className={classes.formFieldsWrapper}>
        <FormTextField name="name" label="Name" />
        <FormSelectField
          name="type"
          label="Account Type"
          options={BANK_ACCOUNT_TYPE_OPTIONS}
          disabled={mode !== 'create' && !canUpdatePassportInfo}
          width="100%"
        />
        <FormTextField
          name="accountNumber"
          label="Account Number"
          disabled={mode !== 'create' && !canUpdatePassportInfo}
        />
        <FormMaskedTextField
          mask="999999999"
          name="routingNumber"
          label="Routing Number (ACH)"
          disabled={mode !== 'create' && !canUpdatePassportInfo}
        />
      </Box>
    </Box>
  );
};

export default FirmTrustAccountForm;
