import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography } from '@mui/material';

import FormTextField from '../../../components/formFields/FormTextField';
import FormSelectField from '../../../components/formFields/FormSelectField';
import { SYSTEM_ACCOUNT_LABELS } from '../types';
import { SelectOption } from '../../../types';

const useStyles = makeStyles()((theme: Theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    maxWidth: '416px',
    width: '100%',
  },
  formFieldsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    alignItems: 'center',
  },
  accountTypeTextContainer: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    width: '100%',
  },
  accountTypeBoldText: {
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontSize: '20px',
    lineHeight: '120%',
    color: theme.palette.common.white,
  },
  accountTypeNameText: {
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '120%',
    color: theme.palette.common.white,
  },
}));

interface FirmBankingWithdrawFormProps {
  disabled?: boolean;
  unrestrictedAccountOptionsData: SelectOption[];
  selectedAccountType: string;
}

const FirmBankingWithdrawForm = ({
  disabled,
  unrestrictedAccountOptionsData,
  selectedAccountType,
}: FirmBankingWithdrawFormProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.formContainer}>
      <Box className={classes.formFieldsWrapper}>
        <Box className={classes.accountTypeTextContainer}>
          <Typography className={classes.accountTypeBoldText}>From: </Typography>
          <Typography className={classes.accountTypeNameText}>
            {SYSTEM_ACCOUNT_LABELS[selectedAccountType as keyof typeof SYSTEM_ACCOUNT_LABELS]}
          </Typography>
        </Box>
        <FormSelectField
          options={unrestrictedAccountOptionsData}
          name="toUnrestrictedAccountId"
          label="To"
          disabled={disabled}
          width="100%"
        />
        <FormTextField name="amount" label="Amount" disabled={disabled} />
      </Box>
    </Box>
  );
};

export default FirmBankingWithdrawForm;
