import { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from 'tss-react/mui';

import {
  createUnrestrictedAccount,
  updateUnrestrictedAccount,
} from '../../../api/firmsApi/unrestrictedAccountsApi';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import Loader from '../../../components/Loader';
import { firmBankingCreateUnrestrictedAccountFormSchema } from '../../../helpers/formSchemas/formSchemas';
import { FIRM_BANKING_ACCOUNT_CREATE_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import useSnackBar from '../../../hooks/useSnackBar';
import { UnrestrictedAccountShort } from '../../../types';
import { UnrestrictedAccountSubmitFormProps } from '../types';

import FirmUnrestrictedAccountForm from './FirmUnrestrictedAccountForm';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
      maxWidth: '800px',
      width: '100%',
      padding: '32px',
      boxSizing: 'border-box',
      borderRadius: '16px',
    },

    '& .MuiDialogTitle-root': {
      padding: 0,
      fontSize: '34px',
      fontWeight: 600,
      lineHeight: '120%',
      color: theme.palette.common.white,
      fontFamily: 'Poppins',
    },

    '& .MuiDialogContent-root': {
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  popupActions: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '416px',
    width: '100%',
    margin: '0 auto',
    gap: '16px',
    padding: 0,
  },
  popupButton: {
    color: theme.palette.info.main,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },

    '&:hover': {
      background: 'rgba(144, 202, 249, 0.08)',
    },
  },
}));

export enum Mode {
  Create = 'create',
  Edit = 'edit',
}

interface FirmUnrestrictedAccountPopupProps {
  isOpen: boolean;
  mode: Mode;
  data?: UnrestrictedAccountShort | null;
  loading?: boolean;
  onSuccess?: () => void;
  onFailed?: () => void;
  onCancel: () => void;
}

const FirmUnrestrictedAccountPopup = ({
  isOpen,
  mode,
  data,
  loading,
  onSuccess,
  onFailed,
  onCancel,
}: FirmUnrestrictedAccountPopupProps) => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();

  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(firmBankingCreateUnrestrictedAccountFormSchema),
    defaultValues: FIRM_BANKING_ACCOUNT_CREATE_DEFAULT_VALUES,
  });

  const {
    formState: { isValid, isDirty },
    handleSubmit,
    reset,
  } = formMethods;

  const handleCreateUnrestrictedAccount = (submitData: UnrestrictedAccountSubmitFormProps) => {
    setIsFormSubmitting(true);

    createUnrestrictedAccount(submitData)
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'Unrestricted account created successfully.',
          type: 'success',
        }));
        onSuccess?.();
      })
      .catch((error) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message,
          type: 'error',
        }));
        onFailed?.();
      })
      .finally(() => {
        setIsFormSubmitting(false);
      });
  };

  const handleUpdateUnrestrictedAccount = (submitData: UnrestrictedAccountSubmitFormProps) => {
    if (!data) {
      return;
    }

    setIsFormSubmitting(true);

    const payload: Partial<UnrestrictedAccountSubmitFormProps> = {
      ...(data.name !== submitData.name && { name: submitData.name }),
      ...(data.type !== submitData.type && { type: submitData.type }),
      ...(data.accountNumber !== submitData.accountNumber && {
        accountNumber: submitData.accountNumber,
      }),
      ...(data.routingNumber !== submitData.routingNumber && {
        routingNumber: submitData.routingNumber,
      }),
    };

    updateUnrestrictedAccount(data.id, payload)
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'Unrestricted account updated successfully.',
          type: 'success',
        }));
        onSuccess?.();
      })
      .catch((error) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message,
          type: 'error',
        }));
        onFailed?.();
      })
      .finally(() => {
        setIsFormSubmitting(false);
      });
  };

  const onSubmitForm = (values: UnrestrictedAccountSubmitFormProps) => {
    switch (mode) {
      case 'create':
        handleCreateUnrestrictedAccount(values);
        return;
      case 'edit':
        handleUpdateUnrestrictedAccount(values);
        return;
      default:
        return;
    }
  };

  const handleClose = () => {
    onCancel();
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (mode === 'create') {
      reset(FIRM_BANKING_ACCOUNT_CREATE_DEFAULT_VALUES);
      return;
    }

    if (mode === 'edit' && data) {
      reset({
        name: data.name,
        type: data.type,
        accountNumber: data.accountNumber,
        routingNumber: data.routingNumber,
      });
    }
  }, [isOpen, mode, data]);

  return (
    <Dialog className={classes.popupContainer} open={isOpen} onClose={handleClose}>
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          disableRipple
          sx={(theme) => ({
            color: theme.palette.common.white,
          })}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle>
        {mode === 'create' ? 'Add Unrestricted Account' : 'Edit Unrestricted Account'}
      </DialogTitle>
      {loading ? (
        <Loader colorType={'warning'} />
      ) : (
        <>
          <DialogContent>
            <FormProvider {...formMethods}>
              <FirmUnrestrictedAccountForm
                mode={mode}
                canUpdatePassportInfo={data?.canUpdatePassportInfo}
              />
            </FormProvider>
          </DialogContent>
          <DialogActions className={classes.popupActions}>
            <FormSubmitButton
              disabled={!isValid || !isDirty || isFormSubmitting}
              buttonText="Submit"
              onClick={handleSubmit(onSubmitForm)}
            />
            <FormCancelButton onClick={handleClose} />
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default FirmUnrestrictedAccountPopup;
