export const CREATE_ADMIN_FORM_DEFAULT_VALUES = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: '',
};

export const CREATE_FIRM_FORM_DEFAULT_VALUES = {
  id: '',
  name: '',
  tin: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  trustAccountNumber: '',
  trustAccountRoutingNumber: '',
  brandColor: '',
  zendeskBrandName: '',
  docusignAccountId: '',
  sendAppInviteTrigger: '',
  createWalletTrigger: '',
  issueVirtualCardTrigger: '',
  issuePhysicalCardTrigger: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  isClientTriggerActive: false,
  docusignIntegrationKey: '',
  docusignUserId: '',
  docusignPublicKey: '',
};

export const MANAGE_ACCOUNT_FORM_DEFAULT_VALUES = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: '',
  isPinSet: false,
};

export const CREATE_USER_FORM_DEFAULT_VALUES = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: '',
  firmId: '',
};

export const DEFENDANT_FORM_DEFAULT_VALUES = {
  id: '',
  firmId: '',
  name: '',
  referenceId: '',
};

export const CLIENT_CREATE_FORM_DEFAULT_VALUES = {
  firstName: '',
  middleName: '',
  lastName: '',
  referenceId: '',
  email: '',
  phone: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  birthdate: null,
  ssn: '',
};

export const CLIENT_EDIT_FORM_DEFAULT_VALUES = {
  id: '',
  firstName: '',
  middleName: '',
  lastName: '',
  referenceId: '',
  email: '',
  phone: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
};

export const CASES_FORM_DEFAULT_VALUES = {
  id: '',
  referenceId: '',
  caseTypeCategory: '',
  type: { id: '', label: '' },
  trustAccount: { id: '', label: '' },
  firmId: '',
  filedAt: '',
  sol: '',
  clientId: { id: '', label: '' },
  defendant: [
    {
      defendantId: { id: '', label: '' },
      gross: '',
    },
  ],
  secondaryFirms: [],
};

export const FIRM_CODES_FORM_DEFAULT_VALUES = {
  code: '',
  name: '',
  dataFormat: '',
  category: '',
  prompt: '',
};

export const CASES_EDIT_FORM_DEFAULT_VALUES = {
  id: '',
  name: '',
  referenceId: '',
  caseTypeCategory: '',
  type: { id: '', label: '' },
  trustAccount: { id: '', label: '' },
  feePercentage: '',
  filedAt: '',
  sol: '',
  liens: '',
  advances: '',
  clientId: { id: '', label: '' },
  defendant: [
    {
      defendantId: { id: '', label: '' },
      gross: '',
    },
  ],
  secondaryFirms: [],
  paymentStarted: false,
};

export const DEDUCTIONS_CREATE_FORM_DEFAULT_VALUES = {
  id: '',
  isAssigned: false,
  defendant: { id: '', label: '' },
  type: '',
  amount: '',
};

export const SETTLEMENTS_CREATE_FORM_DEFAULT_VALUES = {
  id: '',
  defendant: { id: '', label: '' },
  amount: 0,
};

export const DEDUCTIONS_EDIT_FORM_DEFAULT_VALUES = {
  id: '',
  caseReferenceId: '',
  caseSystemName: '',
  firm: '',
  isAssigned: false,
  defendant: { id: '', label: '' },
  amount: '',
  type: '',
};

export const SETTLEMENTS_EDIT_FORM_DEFAULT_VALUES = {
  id: 0,
  caseReferenceId: '',
  caseSystemName: '',
  firm: '',
  defendant: { id: 0, label: '' },
  plaintiff: {
    id: 0,
    fullName: '',
  },
  deduction: [],
};

export const DOCUMENTS_CREATE_FORM_DEFAULT_VALUES = {
  name: '',
};

export const DOCUMENTS_EDIT_FORM_DEFAULT_VALUES = {
  id: '',
  status: '',
  updatedAt: '',
  case: { id: '', name: '', referenceId: '' },
  completedAt: '',
  createdAt: '',
  fileId: '',
  firm: { id: '', name: '' },
  name: '',
  plaintiffFullName: '',
  documentUrl: '',
  plaintiffId: '',
};

export const SIGNATURES_CREATE_FORM_DEFAULT_VALUES = {
  caseId: { id: '', label: '' },
  name: '',
  documentId: '',
};

export const SIGNATURES_EDIT_FORM_DEFAULT_VALUES = {
  id: '',
  status: '',
  updatedAt: '',
  case: { id: '', name: '', referenceId: '' },
  completedAt: '',
  createdAt: '',
  firm: { id: '', name: '' },
  name: '',
  documentId: '',
  plaintiff: { id: '', firstName: '', middleName: '', lastName: '' },
  plaintiffFullName: '',
  documentUrl: '',
  fileId: '',
};

export const DATA_CREATE_FORM_DEFAULT_VALUES = {
  caseId: 0,
  name: '',
  notes: '',
  message: '',
};

export const DATA_EDIT_FORM_DEFAULT_VALUES = {
  form: null,
  answers: [],
  status: '',
  id: 0,
  name: '',
  case: {
    id: 0,
    name: '',
    referenceId: '',
    type: {
      id: 0,
      name: '',
    },
  },
  firm: {
    id: 0,
    name: '',
  },
  plaintiff: {
    id: 0,
    firstName: '',
    middleName: '',
    lastName: '',
  },
  plaintiffFullName: '',
  notes: '',
  message: '',
  formItemsCount: 0,
  formItemAnswersCount: 0,
};

export const SIGN_IN_FORM_DEFAULT_VALUES = {
  email: '',
  password: '',
};

export const CHANGE_PASSWORD_FORM_DEFAULT_VALUES = {
  newPassword: '',
  repeatNewPassword: '',
};

export const FORGOT_PASSWORD_FORM_DEFAULT_VALUES = {
  email: '',
};

export const RESET_PASSWORD_FORM_DEFAULT_VALUES = {
  code: '',
  newPassword: '',
  repeatNewPassword: '',
};

export const CASE_ATTORNEYS_FEES_FORM_DEFAULT_VALUES = {
  caseFeesConfig: {
    variableFeePercent: '',
    maximumAmount: '',
    variableFeeBasis: '',
  },
  secondaryFirms: [],
};

export const REQUEST_DEMO_FORM_DEFAULT_VALUES = {
  userName: '',
  email: '',
  message: '',
  isAgreed: false,
};

export const QUESTIONNAIRE_FORM_BUILDER_DEFAULT_VALUES = {
  name: '',
  form: {
    sections: [
      {
        title: '',
        items: [],
        immutable: false,
      },
    ],
  },
};

export const DATA_REQUEST_FORM_BUILDER_DEFAULT_VALUES = {
  form: {
    sections: [
      {
        title: '',
        items: [],
        immutable: false,
      },
    ],
  },
};

export const FORM_SNIPPET_BUILDER_DEFAULT_VALUES = {
  name: '',
  data: {
    items: [],
  },
};

export const ACCOUNT_DELETION_REQUEST_DEFAULT_VALUES = {
  email: '',
};

export const REQUEST_SUPPORT_FORM_DEFAULT_VALUES = {
  name: '',
  email: '',
  message: '',
};

export const EMAIL_CONFIRMATION_FORM_DEFAULT_VALUES = {
  ssn: '',
  birthdate: null,
};

export const EDIT_CLIENT_SSN_DOB_FORM_DEFAULT_VALUES = {
  ssn: '',
  birthdate: null,
};

export const CONFIRM_2FA_CODE_FORM_DEFAULT_VALUES = {
  verificationCode: '',
};

export const FIRM_BANKING_ACCOUNT_CREATE_DEFAULT_VALUES = {
  name: '',
  type: '',
  accountNumber: '',
  routingNumber: '',
};

export const FIRM_TRUST_ACCOUNT_FORM_DEFAULT_VALUES = {
  name: '',
  type: '',
  accountNumber: '',
  routingNumber: '',
};

export const FIRM_WITHDRAW_FUNDS_FORM_DEFAULT_VALUES = {
  fromSystemAccountType: '',
  toUnrestrictedAccountId: '',
  amount: '',
};

export const DATA_FILE_DOWNLOAD_TEMPLATE_FORM_DEFAULT_VALUES = {
  fileType: '',
};

export const DATA_FILE_UPLOAD_FORM_DEFAULT_VALUES = {
  fileType: '',
  fileName: '',
  sendVia: 'SMS',
  access: 'appOnly',
  sendInvitationVia: false,
  fileData: '',
};

export const PAY_SETTLEMENT_FORM_DEFAULT_VALUES = {
  hold: false,
  holdUntil: null,
  numbers: new Array(4).fill({ value: '' }),
};
