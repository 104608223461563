import api from '../service/api.service';

import {
  PaginationQueryParams,
  ResultSet,
  UnrestrictedAccount,
  UnrestrictedAccountShort,
} from '../../types';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL = 'v1/fo/banking/unrestricted-accounts';

export const getUnrestrictedAccount = async (accountId: number) => {
  const targetUrl = `${BASE_URL}/${accountId}`;

  return await api.get<UnrestrictedAccount>(targetUrl);
};

export const updateUnrestrictedAccount = async (
  accountId: number,
  data: {
    name?: string;
    accountNumber?: string;
    routingNumber?: string;
    type?: string;
  },
) => {
  const targetUrl = `${BASE_URL}/${accountId}`;

  return await api.put<UnrestrictedAccount>(targetUrl, data);
};

export const deleteUnrestrictedAccount = async (accountId: number) => {
  const targetUrl = `${BASE_URL}/${accountId}`;

  return await api.delete<void>(targetUrl);
};

export const getUnrestrictedAccounts = async ({
  size,
  page,
  count,
  activeSortField,
}: PaginationQueryParams) => {
  const sortFilters = parseQuerySortFields(activeSortField);
  const targetUrl = `${BASE_URL}?size=${size}&page=${page}&count=${count}${sortFilters}`;

  return await api.get<ResultSet<UnrestrictedAccountShort>>(targetUrl);
};

export const createUnrestrictedAccount = async (data: {
  name: string;
  accountNumber: string;
  routingNumber: string;
  type: string;
}) => {
  return await api.post<UnrestrictedAccount>(BASE_URL, data);
};

/**
 * Start/retry/continue passport provisioning for a specific unrestricted account.
 * Can be performed in case if it has not been performed before, OR if the previous provisioning attempt ended with a non-critical error.
 */
export const startUnrestrictedAccountProvisioning = async (accountId: number) => {
  const targetUrl = `${BASE_URL}/${accountId}/start-provisioning`;

  return await api.post<void>(targetUrl);
};
