import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { getFirmTransactions } from '../../../api/bankingApi/bankingApi';
import TableContainer from '../../../components/Table/TableContainer';
import TableFilters from '../../../components/TableFilters/TableFilters';
import TableActions from '../../../components/Table/TableActions';
import Loader from '../../../components/Loader';
import NoDataText from '../../../components/NoDataText';
import { FIRM_TRANSACTION_LIST_TABLE_LABELS } from '../../../constants/tableLabels';
import { handleSortTableData } from '../../../helpers/handleSortTableData';
import usePagination from '../../../hooks/usePagination';
import useSnackBar from '../../../hooks/useSnackBar';
import { ActiveSortField, FirmTransaction, FilterTypes } from '../../../types';

import FirmTransactionsTableDataRow from './FirmTransactionsTableDataRow';

const FirmBankingTransactionsView = () => {
  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();
  const { setAlert } = useSnackBar();

  const [transactions, setTransactions] = useState<FirmTransaction[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<FilterTypes>({
    from: '',
    to: '',
  });
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);

  const sortableFields = {
    lastUpdatedOn: {
      order: '',
      fieldName: 'lastUpdatedOn',
    },
  };

  useEffect(() => {
    getFirmTransactions({
      page: pageNumber,
      size: rowsPerPage,
      filters,
      activeSortField,
    })
      .then((res) => {
        if (res.data.resultSet) {
          setTotalCount && setTotalCount(res.data.totalCount || 0);
          setTransactions(res.data.resultSet);
        }
      })
      .catch((error) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => setIsLoading(false));
  }, [pageNumber, rowsPerPage, filters, activeSortField]);

  if (isLoading) {
    return (
      <Box position="absolute" top="50%" left="50%">
        <Loader colorType="warning" />
      </Box>
    );
  }

  return (
    <>
      <TableActions showFilters={showFilters} setShowFilters={setShowFilters} />

      {showFilters && <TableFilters filters={filters} setFilters={setFilters} dateRange />}

      {transactions.length === 0 ? (
        <NoDataText />
      ) : (
        <TableContainer
          headerLabels={FIRM_TRANSACTION_LIST_TABLE_LABELS}
          sortableFields={sortableFields}
          activeSortField={activeSortField}
          setActiveSortField={setActiveSortField}
          handleSortTableData={handleSortTableData}
        >
          <FirmTransactionsTableDataRow tableData={transactions} />
        </TableContainer>
      )}
    </>
  );
};

export default FirmBankingTransactionsView;
