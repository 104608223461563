import { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';

import {
  getUnrestrictedAccount,
  startUnrestrictedAccountProvisioning,
} from '../../../api/firmsApi/unrestrictedAccountsApi';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import Loader from '../../../components/Loader';
import useSnackBar from '../../../hooks/useSnackBar';
import { UnrestrictedAccount } from '../../../types';
import NoDataText from '../../../components/NoDataText';
import { StatusChip } from '../../../components/StatusChip';
import CommonButton from '../../../components/buttons/CommonButton';
import {
  EwsValidationStatusMap,
  ExternalAccountStatusMap,
  ProvisioningStatusMap,
} from '../constants';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
      maxWidth: '800px',
      width: '100%',
      padding: '32px',
      boxSizing: 'border-box',
      borderRadius: '16px',
    },

    '& .MuiDialogTitle-root': {
      fontFamily: 'Poppins',
      fontSize: '34px',
      fontWeight: 600,
      padding: '20px 0px 32px 0px',
      color: theme.palette.common.white,
    },

    '& .MuiDialogContent-root': {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      maxWidth: '416px',
      width: '100%',
      gap: '32px',
    },
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '33.6px',
    color: theme.palette.common.white,
  },
  subtitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.common.white,
  },
  field: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19.2px',
    color: theme.palette.common.white,
  },
  value: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19.2px',
    color: theme.palette.text.secondary,
  },
  popupActions: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '416px',
    width: '100%',
    margin: '0 auto',
    gap: '16px',
    padding: 0,
  },
}));

interface FirmUnrestrictedAccountDetailsPopupProps {
  isOpen: boolean;
  accountId: number;
  onClose: () => void;
}

const FirmUnrestrictedAccountDetailsPopup = ({
  isOpen,
  accountId,
  onClose,
}: FirmUnrestrictedAccountDetailsPopupProps) => {
  const { palette } = useTheme();
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [unrestrictedAccount, setUnrestrictedAccount] = useState<UnrestrictedAccount | null>(null);

  const canStartProvisioning = unrestrictedAccount?.integration.canStartProvisioning;
  const provisioning = unrestrictedAccount?.integration.provisioning;
  const externalAccount = unrestrictedAccount?.integration.externalAccount;

  const fetchUnrestrictedAccount = () => {
    setIsLoading(true);

    getUnrestrictedAccount(accountId)
      .then(({ data }) => {
        setUnrestrictedAccount(data);
      })
      .catch((error) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message,
          type: 'error',
        }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleStartProvisioning = () => {
    setIsLoading(true);

    startUnrestrictedAccountProvisioning(accountId)
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'Provisioning started successfully.',
          type: 'success',
        }));
        onClose();
      })
      .catch((error) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message,
          type: 'error',
        }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loader colorType="warning" />;
    }

    if (canStartProvisioning) {
      return (
        <DialogContent>
          <Typography className={classes.title}>Integration Details</Typography>
          <CommonButton
            type="infoMain"
            buttonText="Start"
            onButtonClick={handleStartProvisioning}
          />
        </DialogContent>
      );
    }

    if (provisioning) {
      return (
        <>
          <DialogContent>
            <Typography className={classes.title}>Integration Details</Typography>
            <Box display="flex" flexDirection="column" gap="24px">
              <Typography className={classes.subtitle}>Provisioning</Typography>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography className={classes.field}>Provisioning Status</Typography>
                <StatusChip
                  label={ProvisioningStatusMap[provisioning.status].label}
                  color={ProvisioningStatusMap[provisioning.status].color}
                />
              </Box>
              {provisioning.error?.message && (
                <Typography
                  fontFamily="Inter"
                  fontWeight={500}
                  fontSize="14px"
                  color={palette.error.main}
                >
                  {'Error: '}
                  <Typography
                    fontFamily="Inter"
                    fontWeight={500}
                    fontSize="14px"
                    color={palette.common.white}
                    component="span"
                  >
                    {provisioning.error.message}
                  </Typography>
                </Typography>
              )}
            </Box>
            {externalAccount && (
              <Box display="flex" flexDirection="column" gap="24px">
                <Typography className={classes.subtitle}>External Account</Typography>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography className={classes.field}>Account Status</Typography>
                  <StatusChip
                    label={ExternalAccountStatusMap[externalAccount.status].label}
                    color={ExternalAccountStatusMap[externalAccount.status].color}
                  />
                </Box>
                {externalAccount.ewsValidation && (
                  <>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                      <Typography className={classes.field}>EWS Status</Typography>
                      <StatusChip
                        label={EwsValidationStatusMap[externalAccount.ewsValidation.status].label}
                        color={EwsValidationStatusMap[externalAccount.ewsValidation.status].color}
                      />
                    </Box>
                  </>
                )}
                {externalAccount.bank && (
                  <>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                      <Typography className={classes.field}>Bank Name</Typography>
                      <Typography className={classes.value}>{externalAccount.bank.name}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                      <Typography className={classes.field}>Bank Routing Number</Typography>
                      <Typography className={classes.value}>
                        {externalAccount.bank.routingNumber}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            )}
          </DialogContent>
          <DialogActions className={classes.popupActions}>
            <FormCancelButton onClick={onClose} />
          </DialogActions>
        </>
      );
    }

    return <NoDataText />;
  };

  useEffect(() => {
    if (isOpen) {
      setUnrestrictedAccount(null);
      fetchUnrestrictedAccount();
    }
  }, [isOpen]);

  return (
    <Dialog className={classes.popupContainer} open={isOpen} onClose={onClose}>
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          aria-label="close"
          onClick={onClose}
          disableRipple
          sx={(theme) => ({
            color: theme.palette.common.white,
          })}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle>Unrestricted Account Details</DialogTitle>
      {renderContent()}
    </Dialog>
  );
};

export default FirmUnrestrictedAccountDetailsPopup;
