export const parseStatusTitle = (status: string) => {
  switch (status) {
    case 'CancelInProgress':
      return 'Cancel In Progress';
    case 'CriticalError':
      return 'Critical Error';
    case 'OnHold':
      return 'On Hold';
    case 'DistributingFunds':
      return 'Distributing Funds';
    case 'PaymentInProgress':
      return 'Payment In Progress';
    case 'FundsReceived':
      return 'Funds Received';
    case 'WaitingFunds':
      return 'Waiting Funds';
    case 'InitialFunding':
      return 'Initial Funding';
    case 'DistributingAttyFees':
      return 'Distributing Atty Fees';
    case 'DistributingDeductions':
      return 'Distributing Deductions';
    case 'DistributingNetSettlement':
      return 'Distributing Net Settlement';
    default:
      return status;
  }
};
