import { Navigate, Route, Routes } from 'react-router-dom';

import SignInView from '../views/SignInView/SignInView';
import AuthVerifyView from '../views/SignInView/AuthVerifyView';
import ProtectedRoute from './ProtectedRoute';
import ManageAccountView from '../views/ManageAccountView/ManageAccountView';
import NoMatchRoute from './NoMatchRoute';
import UsersView from '../views/UsersView/UsersView';
import CasesView from '../views/CasesView/CasesView';
import ClientsView from '../views/ClientsView/ClientsView';
import DefendantsView from '../views/DefendantsView/DefendantsView';
import FileManagerView from '../views/FileManagerView/FileManagerView';
import CommunicationsView from '../views/CommunicationsView/CommunicationsView';
import FinancialView from '../views/FinancialView/FinancialView';
import UsersCreateFormContainer from '../views/UsersView/UsersCreateFormContainer';
import UsersEditFormContainer from '../views/UsersView/UsersEditFormContainer';
import UsersAccountView from '../views/UsersView/UsersAccountView';
import DefendantsCreateFormContainer from '../views/DefendantsView/DefendantsCreateFormContainer';
import DefendantsEditFormContainer from '../views/DefendantsView/DefendantsEditFormContainer';
import PaginationProvider from '../providers/PaginationProvider';
import ClientCreateFormContainer from '../views/ClientsView/ClientCreateFormContainer';
import ClientsEditFormContainer from '../views/ClientsView/ClientsEditFormContainer';
import ClientAccountView from '../views/ClientsView/ClientAccountView';
import CasesCreateFormContainer from '../views/CasesView/CasesCreateFormContainer';
import CasesEditFormContainer from '../views/CasesView/CasesEditFormContainer';
import CaseAccountView from '../views/CasesView/CaseAccountView';
import CreateDeductionFormContainer from '../views/PaymentsView/DeductionsView/CreateDeductionFormContainer';
// import CreateSettlementFormContainer from '../views/PaymentsView/SettlementsView/CreateSettlementFormContainer';
import DefendantAccountView from '../views/DefendantsView/DefendantAccountView';
import DeductionsView from '../views/PaymentsView/DeductionsView/DeductionsView';
import SettlementsView from '../views/PaymentsView/SettlementsView/SettlementsView';
import DeductionsEditFormContainer from '../views/PaymentsView/DeductionsView/DeductionsEditFormContainer';
import DeductionAccountView from '../views/PaymentsView/DeductionsView/DeductionAccountView';
import SettlementAccountView from '../views/PaymentsView/SettlementsView/SettlementAccountView';
import SettlementsEditFormContainer from '../views/PaymentsView/SettlementsView/SettlementsEditFormContainer';
import DataView from '../views/RequestsView/DataView/DataView';
import SignaturesView from '../views/RequestsView/SignaturesView/SignaturesView';
import DocumentsView from '../views/RequestsView/DocumentsView/DocumentsView';
import CreateDataFormContainer from '../views/RequestsView/DataView/CreateDataFormContainer';
import DataAccountView from '../views/RequestsView/DataView/DataAccountView';
// import CreateSignatureFormContainer from '../views/RequestsView/SignaturesView/CreateSignatureFormContainer';
import SignaturesAccountView from '../views/RequestsView/SignaturesView/SignaturesAccountView';
import CreateDocumentFormContainer from '../views/RequestsView/DocumentsView/CreateDocumentFormContainer';
import DocumentsAccountView from '../views/RequestsView/DocumentsView/DocumentsAccountView';
import SignaturesEditFormContainer from '../views/RequestsView/SignaturesView/SignaturesEditFormContainer';
import DocumentsEditFormContainer from '../views/RequestsView/DocumentsView/DocumentsEditFormContainer';
import DataEditFormContainer from '../views/RequestsView/DataView/DataEditFormContainer';
import AuthTemporaryPasswordView from '../views/SignInView/AuthTemporaryPasswordView';
import ForgotPasswordView from '../views/SignInView/ForgotPasswordView';
import ResetPasswordView from '../views/SignInView/ResetPasswordView';
import DocumentsTimeline from '../views/RequestsView/DocumentsView/DocumentsTimeline';
import SignaturesTimeline from '../views/RequestsView/SignaturesView/SignaturesTimeline';
import DataTimeline from '../views/RequestsView/DataView/DataTimeline';
import DeductionsTimeline from '../views/PaymentsView/DeductionsView/DeductionsTimeline';
import PaymentsAccountView from '../views/PaymentsView/PaymentsAccountView';
import RequestsAccountView from '../views/RequestsView/RequestsAccountView';
import SettlementsTimeline from '../views/PaymentsView/SettlementsView/SettlementsTimeline';
import CaseTypesView from '../views/CaseTypesView/CaseTypesView';
import LandingView from '../views/LandingView/LandingView';
import CaseAttorneyFeesView from '../views/CasesView/CasesAttorneyFeesView';
// import ClientBankingView from '../views/ClientsView/clientBanking/ClientBankingView';
import PrivacyPolicyView from '../views/LandingView/LegalAgreementsView/PrivacyPolicyView';
import TermsConditionsView from '../views/LandingView/LegalAgreementsView/TermsConditionsView';
import TermsOfUseView from '../views/LandingView/LegalAgreementsView/TermsOfUseView';
import DataProcessingAbbendumView from '../views/LandingView/LegalAgreementsView/DataProcessingAbbendumView';
import DataPrivacyFrameworkNoticeView from '../views/LandingView/LegalAgreementsView/DataPrivacyFrameworkNoticeView';
import FormSnippetsView from '../views/FormSnippetsView/FormSnippetsView';
import QuestionnairesView from '../views/QuestionnairesView/QuestionnairesView';
import SettlementsDetailsView from '../views/PaymentsView/SettlementsView/SettlementsDetailsView';
import DataSafetyView from '../views/DataSafetyView/DataSafetyView';
import SupportPageView from '../views/SupportPageView/SupportPageView';
import DeletePlaintiffAccountView from '../views/DataSafetyView/DeletePlaintiffAccountView';
import MobileAppPrivacyPolicyView from '../views/LandingView/LegalAgreementsView/MobileAppPrivacyPolicyView';
import EmailConfirmView from '../views/EmailConfirmView/EmailConfirmView';
import CreateSettlementFormContainer from '../views/PaymentsView/SettlementsView/CreateSettlementFormContainer';
import Confirm2FACodeView from '../views/SignInView/Confirm2FACodeView';
import FirmBankingAccountsView from '../views/FirmBankingView/FirmBankingAccountsView';
import FirmBankingView from '../views/FirmBankingView/FirmBankingView';
import FirmBankingTransactionsView from '../views/FirmBankingView/transactionsView/FirmBankingTransactionsView';

const AppRoutes = () => {
  return (
    <Routes>
      <Route index element={<LandingView />} />
      <Route path="privacy-policy" element={<PrivacyPolicyView />} />
      <Route path="terms-and-conditions" element={<TermsConditionsView />} />
      <Route path="terms-of-use" element={<TermsOfUseView />} />
      <Route path="data-processing-addendum" element={<DataProcessingAbbendumView />} />
      <Route path="data-privacy-framework-notice" element={<DataPrivacyFrameworkNoticeView />} />
      <Route path="data-safety" element={<DataSafetyView />} />
      <Route path="data-safety/delete-account" element={<DeletePlaintiffAccountView />} />
      <Route path="confirm-email" element={<EmailConfirmView />} />

      <Route path="support" element={<SupportPageView />} />
      <Route path="app-privacy" element={<MobileAppPrivacyPolicyView />} />

      <Route path="sign-in" element={<SignInView />} />
      <Route path="auth/callback" element={<AuthVerifyView />} />
      <Route path="changePassword" element={<AuthTemporaryPasswordView />} />
      <Route path="forgotPassword" element={<ForgotPasswordView />} />
      <Route path="resetPassword" element={<ResetPasswordView />} />
      <Route path="confirm-2fa-code" element={<Confirm2FACodeView />} />

      <Route path="cases">
        <Route
          index
          element={
            <ProtectedRoute>
              <PaginationProvider key="casesView">
                <CasesView />
              </PaginationProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="create-case"
          element={
            <ProtectedRoute>
              <CasesCreateFormContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path=":caseId"
          element={
            <ProtectedRoute>
              <CaseAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <CasesEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="attorneys-fees"
            element={
              <ProtectedRoute>
                <CaseAttorneyFeesView />
              </ProtectedRoute>
            }
          />
          <Route
            path="document-requests"
            element={
              <ProtectedRoute>
                <PaginationProvider>
                  <DocumentsView />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="data-requests"
            element={
              <ProtectedRoute>
                <PaginationProvider>
                  <DataView />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="signature-requests"
            element={
              <ProtectedRoute>
                <PaginationProvider>
                  <SignaturesView />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="deductions"
            element={
              <ProtectedRoute>
                <PaginationProvider>
                  <DeductionsView />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="settlements"
            element={
              <ProtectedRoute>
                <PaginationProvider>
                  <SettlementsView />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>
      <Route path="case-types">
        <Route
          index
          element={
            <ProtectedRoute>
              <CaseTypesView />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route path="clients">
        <Route
          index
          element={
            <ProtectedRoute>
              <PaginationProvider key="clientsView">
                <ClientsView />
              </PaginationProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="create-client"
          element={
            <ProtectedRoute>
              <ClientCreateFormContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path=":clientId"
          element={
            <ProtectedRoute>
              <ClientAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <ClientsEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="activity"
            element={
              <ProtectedRoute>
                <div>Page is in development</div>
              </ProtectedRoute>
            }
          />
          <Route
            path="cases"
            element={
              <ProtectedRoute>
                <PaginationProvider>
                  <CasesView />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="banking"
            element={
              <ProtectedRoute>
                <ClientBankingView />
              </ProtectedRoute>
            }
          /> */}
        </Route>
      </Route>

      <Route path="defendants">
        <Route
          index
          element={
            <ProtectedRoute>
              <PaginationProvider key="defendantsView">
                <DefendantsView />
              </PaginationProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="create-defendant"
          element={
            <ProtectedRoute>
              <DefendantsCreateFormContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path=":defendantId"
          element={
            <ProtectedRoute>
              <DefendantAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <DefendantsEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="cases"
            element={
              <ProtectedRoute>
                <PaginationProvider>
                  <CasesView />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route path="snippets">
        <Route
          index
          element={
            <ProtectedRoute>
              <FormSnippetsView />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route path="questionnaires">
        <Route
          index
          element={
            <ProtectedRoute>
              <QuestionnairesView />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path="requests"
        element={
          <ProtectedRoute>
            <RequestsAccountView />
          </ProtectedRoute>
        }
      >
        <Route index element={<Navigate to="document-requests" />} />
        <Route path="document-requests">
          <Route
            index
            element={
              <ProtectedRoute>
                <PaginationProvider key="documentsView">
                  <DocumentsView />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="signature-requests">
          <Route
            index
            element={
              <ProtectedRoute>
                <PaginationProvider key="signaturesView">
                  <SignaturesView />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="data-requests">
          <Route
            index
            element={
              <ProtectedRoute>
                <PaginationProvider key="dataView">
                  <DataView />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route path="requests">
        <Route
          path="create-document-request"
          element={
            <ProtectedRoute>
              <CreateDocumentFormContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="document-request/:documentId"
          element={
            <ProtectedRoute>
              <DocumentsAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <DocumentsEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="timeline"
            element={
              <ProtectedRoute>
                <DocumentsTimeline />
              </ProtectedRoute>
            }
          />
        </Route>

        {/* <Route
          path="create-signature-request"
          element={
            <ProtectedRoute>
              <CreateSignatureFormContainer />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="signature-request/:signatureId"
          element={
            <ProtectedRoute>
              <SignaturesAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <SignaturesEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="timeline"
            element={
              <ProtectedRoute>
                <SignaturesTimeline />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path="create-data-request"
          element={
            <ProtectedRoute>
              <CreateDataFormContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="data-request/:dataId"
          element={
            <ProtectedRoute>
              <DataAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <DataEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="timeline"
            element={
              <ProtectedRoute>
                <DataTimeline />
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route
        path="payments"
        element={
          <ProtectedRoute>
            <PaymentsAccountView />
          </ProtectedRoute>
        }
      >
        <Route index element={<Navigate to="deductions" />} />
        <Route path="deductions">
          <Route
            index
            element={
              <ProtectedRoute>
                <PaginationProvider key="deductionsView">
                  <DeductionsView />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="settlements">
          <Route
            index
            element={
              <ProtectedRoute>
                <PaginationProvider key="settlementsView">
                  <SettlementsView />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route path="payments">
        <Route
          path="create-deduction"
          element={
            <ProtectedRoute>
              <CreateDeductionFormContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="deduction/:deductionId"
          element={
            <ProtectedRoute>
              <DeductionAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <DeductionsEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="timeline"
            element={
              <ProtectedRoute>
                <DeductionsTimeline />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="create-settlement" element={<CreateSettlementFormContainer />} />
        <Route
          path="settlement/:settlementId"
          element={
            <ProtectedRoute>
              <SettlementAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <SettlementsEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="details"
            element={
              <ProtectedRoute>
                <SettlementsDetailsView />
              </ProtectedRoute>
            }
          />
          <Route
            path="timeline"
            element={
              <ProtectedRoute>
                <SettlementsTimeline />
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route
        path="file-manager"
        element={
          <ProtectedRoute>
            <FileManagerView />
          </ProtectedRoute>
        }
      />

      <Route
        path="communications"
        element={
          <ProtectedRoute>
            <CommunicationsView />
          </ProtectedRoute>
        }
      />

      <Route
        path="financial"
        element={
          <ProtectedRoute>
            <FinancialView />
          </ProtectedRoute>
        }
      />

      <Route path="users">
        <Route
          index
          element={
            <ProtectedRoute>
              <PaginationProvider key="usersView">
                <UsersView />
              </PaginationProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="create-user"
          element={
            <ProtectedRoute>
              <UsersCreateFormContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path=":lawyerId"
          element={
            <ProtectedRoute>
              <UsersAccountView />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <UsersEditFormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="activity"
            element={
              <ProtectedRoute>
                <div>Page is in development</div>
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route
        path="manage-account"
        element={
          <ProtectedRoute>
            <ManageAccountView />
          </ProtectedRoute>
        }
      />

      <Route
        path="banking"
        element={
          <ProtectedRoute>
            <FirmBankingView />
          </ProtectedRoute>
        }
      >
        <Route index element={<Navigate to="accounts" />} />
        <Route path="accounts">
          <Route
            index
            element={
              <ProtectedRoute>
                <FirmBankingAccountsView />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="transactions">
          <Route
            index
            element={
              <ProtectedRoute>
                <PaginationProvider key="bankingTransactionsView">
                  <FirmBankingTransactionsView />
                </PaginationProvider>
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>

      <Route path="*" element={<NoMatchRoute />} />
    </Routes>
  );
};

export default AppRoutes;
