import { useState } from 'react';
import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { AxiosError } from 'axios';

import { verificationPinFormSchema } from '../helpers/formSchemas/formSchemas';
import useSnackBar from '../hooks/useSnackBar';
import { checkUserPin } from '../api/usersApi/usersApi';
import { ErrorData } from '../types';

import PinInput from './PinInput';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: '802px',
      boxSizing: 'border-box',
      padding: '32px',
      gap: '32px',
      border: `1px solid ${theme.palette.secondary.dark}`,
      borderRadius: '16px',
      backgroundColor: theme.palette.primary.light,
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.common.white,
    },
  },
  dialogTitle: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '34px',
    lineHeight: '40.8px',
    padding: '32px 0px 0px 0px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '0px',
  },
  dialogContentText: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '24px',
  },
  text: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16.8px',
    color: theme.palette.text.disabled,
  },
  pinInputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px',
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '0px',

    '@media (max-width: 550px)': {
      flexDirection: 'column',
      gap: '16px',

      '@media (max-width: 550px)': {
        paddingRight: '16px',
      },
    },
  },
  defaultButtonStyle: {
    height: '48px',
    maxWidth: '416px',
    width: '100%',
    borderRadius: '12px',
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19.2px',
    textTransform: 'none',
    color: theme.palette.common.white,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.08)',
      background: 'rgba(255, 255, 255, 0.08)',
    },

    '@media (max-width: 550px)': {
      maxWidth: '100%',
    },
  },
  submitButton: {
    background: theme.palette.info.main,

    '&:hover': {
      background: theme.palette.info.main,
    },
  },
  cancelButton: {
    background: theme.palette.info.dark,

    '&:hover': {
      background: theme.palette.info.dark,
    },
  },
}));

interface FormProps {
  numbers: Array<{
    value: string;
  }>;
}

const formDefaultValues = {
  numbers: new Array(4).fill({ value: '' }),
};

interface VerificationPopupProps {
  isOpen: boolean;
  onSubmit?: (pin: string) => void;
  /**
   * Executed if the PIN code check is successful.
   *
   * Does not work if you use a custom submit function.
   */
  onSuccess?: (pin: string) => void;
  /**
   * Executed if the PIN code check is fails.
   *
   * Does not work if you use a custom submit function.
   */
  onFailed?: () => void;
  onCancel: () => void;
}

const VerificationPopup = ({
  isOpen,
  onSubmit: onCustomSubmit,
  onSuccess,
  onFailed,
  onCancel,
}: VerificationPopupProps) => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();
  const userId = localStorage.getItem('userId');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formMethods = useForm<FormProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(verificationPinFormSchema),
    defaultValues: formDefaultValues,
  });

  const { handleSubmit, reset } = formMethods;

  const onSubmit = (values: FormProps) => {
    const pin = values.numbers.map((num) => num.value).join('');

    if (onCustomSubmit) {
      onCustomSubmit(pin);
    } else {
      setIsLoading(true);
      checkUserPin(Number(userId), pin)
        .then((response) => {
          if (response.data.isValid) {
            onSuccess?.(pin);
          } else {
            setAlert((prev) => ({
              ...prev,
              message: 'Invalid pin. Please try again.',
              type: 'error',
            }));
            onFailed?.();
          }
        })
        .catch((error: AxiosError<ErrorData>) => {
          setAlert((prev) => ({
            ...prev,
            message: error.response?.data.message || 'Error. Something went wrong...',
            type: 'error',
          }));
          onFailed?.();
        })
        .finally(() => setIsLoading(false));
    }

    reset(formDefaultValues);
  };

  const handleCancel = () => {
    reset(formDefaultValues);
    onCancel?.();
  };

  return (
    <Dialog className={classes.dialogContainer} open={isOpen} onClose={handleCancel}>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={(theme) => ({
          position: 'absolute',
          top: 32,
          right: 32,
          color: theme.palette.common.white,
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle className={classes.dialogTitle}>Verification</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.dialogContentText}>
          Enter 4-digit PIN
        </DialogContentText>
        <FormProvider {...formMethods}>
          <PinInput name="numbers" />
        </FormProvider>
      </DialogContent>
      <DialogActions className={classes.dialogActions} disableSpacing>
        <Button
          className={clsx(classes.defaultButtonStyle, classes.submitButton)}
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          Submit
        </Button>
        <Button
          className={clsx(classes.defaultButtonStyle, classes.cancelButton)}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VerificationPopup;
