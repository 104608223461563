import { ReactNode, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
  FilledInputProps,
  IconButton,
  InputLabelProps,
  InputProps,
  OutlinedInputProps,
  TextField,
  Theme,
} from '@mui/material';
import { InputBaseProps } from '@mui/material/InputBase';

import { TInputMaskCorrect } from './types';

import { validateTextFieldValue } from '../../helpers/validateTextFieldValue';

export const useStyles = makeStyles<{
  maxWidth?: string;
  disabled?: boolean;
  minWidth?: string;
  isEncrypted?: boolean;
}>()((theme: Theme, { maxWidth, disabled, minWidth, isEncrypted }) => ({
  defaultTextField: {
    ...(maxWidth && { maxWidth }),
    ...(minWidth && { minWidth }),
    background: disabled ? 'rgba(255, 255, 255, 0.08)' : 'rgba(255, 255, 255, 0.03)',
    borderRadius: '12px',
    input: {
      padding: '12px 16px',
      color: theme.palette.common.white,

      '&.Mui-disabled': {
        color: isEncrypted ? theme.palette.common.white : theme.palette.text.disabled,
        WebkitTextFillColor: isEncrypted ? theme.palette.common.white : theme.palette.text.disabled,
      },
    },

    '& .MuiOutlinedInput-root': {
      color: theme.palette.text.disabled,
      borderRadius: '12px',

      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
          borderColor: theme.palette.error.main,
        },
      },

      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
          borderColor: theme.palette.info.main,
        },
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.dark,
        borderRadius: '12px',
      },

      '&:hover': {
        border: 'none',

        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
          borderColor: theme.palette.info.main,
        },
      },

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,

        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: 0,
          borderColor: 'none',
        },
      },
    },

    '& .MuiInputLabel-root': {
      color: theme.palette.text.disabled,

      '&[data-shrink="false"]': {
        top: '-3px',
      },

      '&[data-shrink="true"]': {
        color: theme.palette.text.disabled,
      },

      '&.Mui-error': {
        color: theme.palette.error.main,
      },

      '&.Mui-focused': {
        color: theme.palette.common.white,
      },

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
      },
    },

    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-20px',
      marginLeft: '14px',
    },
  },
  readOnlyText: {
    '& .MuiInput-root': {
      maxWidth: '470px',
      color: theme.palette.primary.light,
    },
  },
  multilinePadding: {
    textarea: {
      color: theme.palette.common.white,
    },
  },
}));

// eslint-disable-next-line react/prop-types
const InputMaskCorrect: React.FC<TInputMaskCorrect> = ({ children, ...props }) => {
  const child = children as ReactNode;
  return <InputMask {...props}>{child}</InputMask>;
};

interface FormTextFieldProps {
  name: string;
  label: string;
  readOnly?: boolean;
  maxWidth?: string;
  disabled?: boolean;
  required?: boolean;
  isReadOnly?: boolean;
  displayHidden?: boolean;
  handleChange?: (value: string) => void;
  inputLabelProps?: Partial<InputLabelProps>;
  inputProps?: InputBaseProps['inputProps'];
  minWidth?: string;
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | undefined;
  mask: string;
  isEncrypted?: boolean;
  getEncryptedFieldValue?: () => void;
  isValueVisible?: boolean;
  setIsValueVisible?: () => void;
}

const FormMaskedTextField = ({
  name,
  label,
  handleChange,
  readOnly,
  isReadOnly,
  disabled,
  maxWidth,
  inputLabelProps,
  inputProps,
  InputProps,
  minWidth,
  mask,
  isEncrypted,
  getEncryptedFieldValue,
  isValueVisible,
  setIsValueVisible,
}: FormTextFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const styles = useStyles({ maxWidth, disabled, minWidth, isEncrypted });

  const helperText = errors[name] && errors[name]?.message;

  const getVisiblePropValue = () => {
    if (isEncrypted) {
      if (isValueVisible) {
        return 'text';
      } else {
        return 'password';
      }
    }
  };

  useEffect(() => {
    isValueVisible && getEncryptedFieldValue && getEncryptedFieldValue();
  }, [isValueVisible]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <InputMaskCorrect
            mask={mask}
            maskChar=""
            onChange={(e) =>
              handleChange
                ? handleChange(e.target.value)
                : onChange(validateTextFieldValue(e.target.value))
            }
            value={value}
            disabled={disabled}
          >
            {() => (
              <TextField
                fullWidth
                type={getVisiblePropValue()}
                className={clsx(
                  styles.classes.defaultTextField,
                  isReadOnly && styles.classes.readOnlyText,
                )}
                variant="outlined"
                label={label}
                helperText={helperText as string}
                error={!!errors[name]}
                inputProps={inputProps}
                InputProps={{
                  ...InputProps,
                  readOnly: readOnly,
                  disabled: disabled || isEncrypted,
                  endAdornment: isEncrypted && (
                    <IconButton
                      disableRipple
                      sx={{
                        color: 'white',
                      }}
                      onClick={setIsValueVisible}
                    >
                      {isValueVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  ),
                }}
                InputLabelProps={inputLabelProps}
              />
            )}
          </InputMaskCorrect>
        );
      }}
    />
  );
};

export default FormMaskedTextField;
