import { TrustAccountShort } from '../../../types';

import { Mode } from './FirmTrustAccountPopup';

interface TrustAccountPopup {
  isOpen: boolean;
  mode: Mode;
}

interface TrustAccountsViewState {
  isLoading: boolean;
  trustAccounts: TrustAccountShort[];
  selectedTrustAccount: TrustAccountShort | null;
  trustAccountPopup: TrustAccountPopup;
  isDetailsPopupOpen: boolean;
  isDeletePopupOpen: boolean;
}

type Action =
  | { type: 'SET_IS_LOADING'; isLoading: boolean }
  | { type: 'SET_TRUST_ACCOUNTS'; trustAccounts: TrustAccountShort[] }
  | { type: 'SET_SELECTED_TRUST_ACCOUNT'; trustAccount?: TrustAccountShort | null }
  | { type: 'OPEN_TRUST_ACCOUNT_POPUP'; mode: Mode; trustAccount?: TrustAccountShort | null }
  | { type: 'OPEN_DETAILS_POPUP'; trustAccount: TrustAccountShort }
  | { type: 'OPEN_DELETE_POPUP'; trustAccount: TrustAccountShort }
  | { type: 'CLOSE_POPUP' };

export const initialState: TrustAccountsViewState = {
  isLoading: false,
  trustAccounts: [],
  selectedTrustAccount: null,
  trustAccountPopup: { isOpen: false, mode: Mode.Create },
  isDetailsPopupOpen: false,
  isDeletePopupOpen: false,
};

export const reducer = (state: TrustAccountsViewState, action: Action): TrustAccountsViewState => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.isLoading };
    case 'SET_TRUST_ACCOUNTS':
      return { ...state, trustAccounts: action.trustAccounts };
    case 'SET_SELECTED_TRUST_ACCOUNT':
      return { ...state, selectedTrustAccount: action.trustAccount ?? null };
    case 'OPEN_TRUST_ACCOUNT_POPUP':
      return {
        ...state,
        trustAccountPopup: { isOpen: true, mode: action.mode },
        selectedTrustAccount: action.trustAccount ?? null,
      };
    case 'OPEN_DETAILS_POPUP':
      return { ...state, isDetailsPopupOpen: true, selectedTrustAccount: action.trustAccount };
    case 'OPEN_DELETE_POPUP':
      return { ...state, isDeletePopupOpen: true, selectedTrustAccount: action.trustAccount };
    case 'CLOSE_POPUP':
      return {
        ...state,
        trustAccountPopup: { isOpen: false, mode: Mode.Create },
        isDetailsPopupOpen: false,
        isDeletePopupOpen: false,
        selectedTrustAccount: null,
      };
    default:
      return state;
  }
};
