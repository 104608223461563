import { Moment } from 'moment';
import { useFormContext, Controller } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { Box, CircularProgress, FormControl, IconButton, Theme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles<{
  disabled?: boolean;
  dateValue?: string;
  isEncrypted?: boolean;
  maxWidth?: string;
}>()((theme: Theme, { disabled, dateValue, isEncrypted, maxWidth }) => ({
  defaultTextField: {
    ...(maxWidth && { maxWidth }),
    background: disabled ? 'rgba(255, 255, 255, 0.08)' : 'rgba(255, 255, 255, 0.03)',
    borderRadius: '12px',
    input: {
      padding: '12px 16px',
      color: theme.palette.common.white,

      '&.Mui-disabled': {
        color: isEncrypted ? theme.palette.common.white : theme.palette.text.disabled,
        WebkitTextFillColor: isEncrypted ? theme.palette.common.white : theme.palette.text.disabled,
      },
    },

    '& .MuiOutlinedInput-root': {
      color: theme.palette.text.disabled,
      borderRadius: '12px',

      '& .hiddenIcon': {
        svg: {
          color: theme.palette.common.white,
        },
      },

      svg: {
        color: dateValue ? theme.palette.common.white : theme.palette.text.disabled,
      },

      '& .MuiIconButton-root': {
        marginRight: 0,
      },

      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
          borderColor: theme.palette.error.main,
        },
      },

      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
          borderColor: theme.palette.info.main,
        },
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.dark,
        borderRadius: '12px',
      },

      '&:hover': {
        border: 'none',

        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
          borderColor: theme.palette.info.main,
        },
      },

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,

        '&.Mui-disabled': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
            borderColor: 'none',
          },
        },
      },
    },

    '& .MuiInputLabel-root': {
      color: theme.palette.text.disabled,

      '&[data-shrink="false"]': {
        top: '-3px',
      },

      '&[data-shrink="true"]': {
        color: theme.palette.text.disabled,
      },

      '&.Mui-error': {
        color: theme.palette.error.main,
      },

      '&.Mui-focused': {
        color: theme.palette.common.white,
      },

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
      },
    },

    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
      marginLeft: '14px',
      position: 'absolute',
      bottom: '-20px',
    },
  },
  datePickerPaper: {
    background: theme.palette.primary.light,
    borderRadius: '16px',

    '& .MuiMultiSectionDigitalClock-root::-webkit-scrollbar': {
      width: '5px',
      borderRadius: '20px',
    },
    '& .MuiMultiSectionDigitalClock-root::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.secondary.dark,
    },

    '& .MuiPickersYear-yearButton': {
      backgroundColor: 'rgba(37, 157, 168, 0.08)',

      '&.Mui-selected': {
        backgroundColor: 'rgba(37, 157, 168, 0.72)',

        '&:focus': {
          backgroundColor: 'rgba(37, 157, 168, 0.72)',
        },

        '&:hover': {
          backgroundColor: 'rgba(37, 157, 168, 0.72)',
          border: 'none',
        },
      },

      '&:hover': {
        backgroundColor: 'rgba(37, 157, 168, 0.08) !important',
        border: '2px solid #00667C !important',
      },
    },
  },
  datePickerLayout: {
    '& .MuiDayCalendar-header': {
      justifyContent: 'normal',
      padding: '0 8px',
      gap: '8px',
      span: {
        borderRadius: '8px',
        background: 'rgba(255, 255, 255, 0.03)',
        height: '25px',
        color: '#93959E',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '17px',
        fontFamily: 'Inter',
        width: '40px',
      },
    },

    '& .MuiPickersCalendarHeader-label': {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '24px',
      fontFamily: 'Inter',
    },

    '& .MuiDayCalendar-weekContainer': {
      margin: '8px 0',
      gap: '8px',

      '& .MuiPickersDay-root': {
        margin: 0,
      },

      '& .MuiPickersDay-root:not(.Mui-selected)': {
        border: 'none',

        '&:focus': {
          backgroundColor: 'rgba(37, 157, 168, 0.72)',
        },
      },
    },
    '& .MuiYearCalendar-root': {
      padding: 0,

      '&::-webkit-scrollbar': {
        width: '5px',
        borderRadius: '20px',
      },

      '&::-webkit-scrollbar-thumb': {
        borderRadius: 20,
        backgroundColor: theme.palette.secondary.dark,
      },
    },

    '& .MuiPickersCalendarHeader-root': {
      paddingLeft: '11px',
    },
  },
  datePickerDay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: 'rgba(37, 157, 168, 0.08)',
    margin: 0,
    fontSize: '16px',
    lineHeight: '32px',
    fontWeight: 500,
    fontFamily: 'Inter',

    '&.Mui-selected': {
      backgroundColor: 'rgba(37, 157, 168, 0.72)',

      '&:focus': {
        backgroundColor: 'rgba(37, 157, 168, 0.72)',
      },

      '&:hover': {
        backgroundColor: 'rgba(37, 157, 168, 0.72)',
        border: 'none',
      },
    },

    '&:hover': {
      backgroundColor: 'rgba(37, 157, 168, 0.08) !important',
      border: '2px solid #00667C !important',
    },
  },
  datePickerIconColor: {
    color: theme.palette.common.white,
  },
  digitalClock: {
    background: theme.palette.primary.light,
    borderRadius: '8px',

    '& .MuiMultiSectionDigitalClock-section': {
      padding: theme.spacing(1),
    },

    '&.Mui-selected': {
      backgroundColor: 'rgba(37, 157, 168, 0.72)',
      color: theme.palette.common.white,
      borderRadius: '8px',

      '&:focus, &:hover': {
        backgroundColor: 'rgba(37, 157, 168, 0.72)',
        border: 'none',
      },
    },

    '&:hover': {
      backgroundColor: 'rgba(37, 157, 168, 0.08)',
    },

    '& .Mui-disabled': {
      color: theme.palette.text.disabled,
    },
  },
  actionBar: {
    '& .MuiButton-root': {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '400',
      borderRadius: '8px',
      color: theme.palette.common.white,

      '&:hover': {
        backgroundColor: 'rgba(37, 157, 168, 0.08)',
      },
    },
  },
}));

interface FormDateFieldProps {
  name: string;
  label: string;
  displayHidden?: boolean;
  maxDate?: Moment;
  disabled?: boolean;
  readonly?: boolean;
  isEncrypted?: boolean;
  isEncryptedValueLoading?: boolean;
  isValueVisible?: boolean;
  minDate?: Moment;
  maxWidth?: string;
  withTime?: boolean;
  onRightIconClick?: () => void;
}

const FormDateField = ({
  name,
  label,
  disabled,
  maxDate,
  readonly,
  isEncrypted,
  isEncryptedValueLoading,
  isValueVisible,
  minDate,
  maxWidth,
  withTime,
  onRightIconClick,
}: FormDateFieldProps) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const dateValue = watch(name);

  const styles = useStyles({ disabled, dateValue, isEncrypted, maxWidth });

  const helperText = errors[name] && errors[name]?.message;

  const Picker = withTime ? DateTimePicker : DesktopDatePicker;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-us">
          <FormControl error={!!error} fullWidth>
            <Picker
              disableOpenPicker={isEncrypted}
              label={label}
              onChange={(event) => onChange(event)}
              value={value || null}
              disabled={disabled}
              defaultValue={value || null}
              maxDate={maxDate}
              minDate={minDate}
              dayOfWeekFormatter={(day) => day.slice(0, 2)}
              slots={{
                openPickerIcon: CalendarMonthIcon,
              }}
              slotProps={{
                desktopPaper: {
                  className: styles.classes.datePickerPaper,
                },
                digitalClockSectionItem: {
                  className: styles.classes.digitalClock,
                },
                actionBar: {
                  className: styles.classes.actionBar,
                },
                switchViewIcon: {
                  className: styles.classes.datePickerIconColor,
                },
                nextIconButton: {
                  disableRipple: true,
                  className: styles.classes.datePickerIconColor,
                },
                previousIconButton: {
                  disableRipple: true,
                  className: styles.classes.datePickerIconColor,
                },
                layout: {
                  className: styles.classes.datePickerLayout,
                },
                day: {
                  disableRipple: true,
                  className: styles.classes.datePickerDay,
                },
                textField: {
                  InputProps: {
                    endAdornment:
                      isEncrypted &&
                      (isEncryptedValueLoading ? (
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          padding={'8px'}
                        >
                          <CircularProgress size={24} />
                        </Box>
                      ) : (
                        <IconButton disableRipple onClick={onRightIconClick} className="hiddenIcon">
                          {isValueVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      )),
                  },
                  type: isEncrypted ? (isValueVisible ? 'text' : 'password') : 'text',
                  className: styles.classes.defaultTextField,
                  helperText: helperText as string,
                  error: !!error,
                  label,
                  disabled: readonly,
                },
              }}
            />
          </FormControl>
        </LocalizationProvider>
      )}
    />
  );
};

export default FormDateField;
