import { Chip } from '@mui/material';

interface StatusChipProps {
  label: string;
  color: string;
}

export const StatusChip = ({ label, color }: StatusChipProps) => {
  return (
    <Chip
      label={label}
      variant="outlined"
      sx={{
        height: '25px',
        borderRadius: '12px',
        border: `1px solid ${color}`,
        background: `${color}14`,

        '& .MuiChip-label': {
          fontFamily: 'Inter',
          fontSize: '14px',
          fontWeight: '500',
          color: color,
          padding: '4px 12px 4px 12px',
        },
      }}
    />
  );
};
