import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { getDocumentTimeline } from '../../../api/requestsApi/documentsApi';
import Timeline from '../../../components/Timeline';
import { TimelineEvent } from '../../../types';

const DocumentsTimeline = () => {
  const { documentId } = useParams<string>();
  const [documentTimeline, setDocumentTimeline] = useState<TimelineEvent[]>([]);

  useEffect(() => {
    if (!documentId) {
      return;
    }

    getDocumentTimeline(Number(documentId)).then((res) => {
      if (res.data) {
        setDocumentTimeline(res.data);
      }
    });
  }, []);

  return <Timeline data={documentTimeline} />;
};

export default DocumentsTimeline;
