import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import ClientsTableDataRow from './ClientsTableDataRow';

import { getClientsList } from '../../api/clientsApi/clientsApi';
import TableContainer from '../../components/Table/TableContainer';
import Loader from '../../components/Loader';
import { FIRM_CLIENTS_LIST_TABLE_LABELS } from '../../constants/tableLabels';
import usePagination from '../../hooks/usePagination';
import { ActiveSortField, ClientData, FilterTypes } from '../../types';
import NoDataText from '../../components/NoDataText';
import { downloadCsv } from '../../helpers/downloadCsv';
import TableActions from '../../components/Table/TableActions';
import TableFilters from '../../components/TableFilters/TableFilters';
import useSnackBar from '../../hooks/useSnackBar';
import { handleSortTableData } from '../../helpers/handleSortTableData';
// import { CLIENTS_STATUS_FILTER_OPTIONS } from '../../components/TableFilters/constants';

const ClientsView = () => {
  const params = useParams();
  const firmId = localStorage.getItem('firmId');

  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();
  const { setAlert } = useSnackBar();

  const [clientsList, setClientsList] = useState<ClientData[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [showFilters, setShowFilters] = useState<boolean>(true);

  const hasParams = !!Object.entries(params).length;

  const [filters, setFilters] = useState<FilterTypes>({
    firmId: firmId || '',
    clientId: '',
    defendantId: '',
  });
  const [match, setMatch] = useState<string>('');
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);
  const sortableFields = {
    name: {
      order: '',
      fieldName: 'name',
    },
    createdAt: {
      order: '',
      fieldName: 'createdAt',
    },
  };

  useEffect(() => {
    getClientsList({
      page: pageNumber,
      size: rowsPerPage,
      count: pageNumber === 1,
      match: match,
      filters: { ...filters, ...(hasParams && params) },
      activeSortField,
    }).then((res) => {
      if (res.data) {
        pageNumber === 1 && setTotalCount?.(res.data.totalCount || 0);
        setClientsList(res.data.resultSet);
        setIsDataLoading(false);
      }
    });
  }, [pageNumber, rowsPerPage, filters, match, activeSortField]);

  const exportList = () => {
    downloadCsv('/v1/clients/export', 'POST', 'Clients_List', {
      page: pageNumber,
      size: rowsPerPage,
      count: true,
      match: match,
      filters: filters,
    }).then((res) => {
      if (res?.data) {
        setAlert({
          message: 'Clients exported successfully.',
          type: 'success',
        });
      }
    });
  };

  return (
    <>
      {isDataLoading && !clientsList.length ? (
        <Box position="absolute" top="50%" left="50%">
          <Loader colorType="warning" />
        </Box>
      ) : (
        <>
          <TableActions
            hasParams={hasParams}
            createLink="/clients/create-client"
            exportList={exportList}
            match={match}
            showFilters={showFilters}
            setShowFilters={setShowFilters}
            setMatch={setMatch}
          />

          {showFilters && (
            <TableFilters
              filters={filters}
              setFilters={setFilters}
              setMatch={setMatch}
              timeFilter={'Created'}
              match={match}
              // statusFilter={CLIENTS_STATUS_FILTER_OPTIONS}
            />
          )}

          {!isDataLoading && clientsList.length === 0 ? (
            <NoDataText />
          ) : (
            <TableContainer
              headerLabels={FIRM_CLIENTS_LIST_TABLE_LABELS}
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
              handleSortTableData={handleSortTableData}
            >
              <ClientsTableDataRow tableData={clientsList} />
            </TableContainer>
          )}
        </>
      )}
      <Outlet />
    </>
  );
};

export default ClientsView;
