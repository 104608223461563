import { ReactNode, createContext, useState } from 'react';
import { useNavigate } from 'react-router';

export const AuthContext = createContext<AuthContextProps>({
  token: null,
  refreshToken: null,
  onLogOut: undefined,
  isAuth: false,
});

interface AuthContextProps {
  token: string | null;
  refreshToken: string | null;
  isAuth: boolean;
  changeAuth?: (isAuth: boolean) => void;
  onLogOut?: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState<boolean>(false);

  const handleLogOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    setIsAuth(false);
    navigate('/sign-in');
  };

  const changeAuth = (isAuth: boolean) => {
    setIsAuth(isAuth);
  };

  const value = {
    token: localStorage.getItem('access_token'),
    refreshToken: localStorage.getItem('refresh_token'),
    onLogOut: handleLogOut,
    isAuth,
    changeAuth,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
