import { useState } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import PasswordChecklist from 'react-password-checklist';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography, Theme, useTheme, useMediaQuery } from '@mui/material';

import { SetPermanentPasswordFormProps } from './types';

import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormPasswordField from '../../components/formFields/FormPasswordField';
import useAuth from '../../hooks/useAuth';
import { CHANGE_PASSWORD_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { changePasswordFormSchema } from '../../helpers/formSchemas/formSchemas';
import useSnackBar from '../../hooks/useSnackBar';
import { setPermanentPassword } from '../../api/authApi/authApi';

const useStyles = makeStyles()((theme: Theme) => ({
  signInBackground: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'linear-gradient(to left bottom, rgba(37, 157, 168, 0.19), rgba(37, 157, 168, 0))',

    '@media (max-width: 640px)': {
      padding: '16px',
    },

    '@media (max-height: 700px)': {
      height: 'auto',
    },
  },
  signInHeadline: {
    fontWeight: '600',
    fontSize: '64px',
    lineHeight: '72px',
    color: theme.palette.common.white,
    fontFamily: 'Poppins',

    '@media (max-width: 640px)': {
      width: '100%',
      textAlign: 'center',
      fontSize: '32px',
      lineHeight: '40px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    '@media (max-height: 700px)': {
      marginTop: '40px',
    },
  },
  signInFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.primary.light,
    borderRadius: '16px',
    border: `1px solid ${theme.palette.secondary.dark}`,
    padding: '32px',
    marginTop: '60px',
    maxWidth: '480px',
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: '64px',

    '@media (max-width: 640px)': {
      padding: '16px',
    },
  },
  signInFormHeadline: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '34px',
    letterSpacing: '0px',
    color: theme.palette.common.white,
  },
  signInFormText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '17px',
    letterSpacing: '0px',
    color: theme.palette.common.white,
  },
  signInFormLink: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '17px',
    letterSpacing: '0px',
    color: theme.palette.info.main,
    textDecoration: 'none',
  },
  signInFormBottomLink: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '19px',
    letterSpacing: '0px',
    color: theme.palette.info.main,
    textAlign: 'center',
    textDecoration: 'none',
  },
  passwordChecklist: {
    li: {
      alignItems: 'center',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '17px',
      letterSpacing: '0px',

      '&.invalid': {
        '& span': {
          opacity: 1,
          color: theme.palette.error.main,
        },
      },

      '&.valid': {
        '& span': {
          opacity: 1,
          color: theme.palette.success.main,
        },
      },
    },
  },
}));

const AuthTemporaryPasswordView = () => {
  const theme = useTheme();
  const styles = useStyles();
  const sm = useMediaQuery('(max-width:640px)');
  const location = useLocation();
  const navigate = useNavigate();

  const { token } = useAuth();

  const [passwordsAreValid, setPasswordsAreValid] = useState<boolean>(false);
  const { setAlert } = useSnackBar();

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(changePasswordFormSchema),
    defaultValues: CHANGE_PASSWORD_FORM_DEFAULT_VALUES,
  });

  const {
    watch,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = formMethods;

  const newPasswordValue = watch('newPassword');
  const repeatNewPasswordValue = watch('repeatNewPassword');

  const onSubmit = (values: SetPermanentPasswordFormProps) => {
    const submitData = {
      email: location.state?.email,
      password: location.state?.password,
      newPassword: values.newPassword,
    };

    setPermanentPassword(submitData)
      .then((res) => {
        if (res.status === 204) {
          setAlert((prev) => ({
            ...prev,
            message: 'Password has been successfully updated.',
            type: 'success',
          }));
          navigate('/sign-in');
        }
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  if (token) {
    return <Navigate to="cases" />;
  }

  return (
    <Box className={styles.classes.signInBackground}>
      <Typography className={styles.classes.signInHeadline}>Change Password</Typography>

      <FormProvider {...formMethods}>
        <form className={styles.classes.signInFormContainer}>
          <Typography className={styles.classes.signInFormHeadline}>
            Please enter your new password below
          </Typography>
          <Box mt={sm ? '12px' : '24px'}>
            <FormPasswordField name="newPassword" label="New Password" />
          </Box>
          <Box mt="24px">
            <FormPasswordField name="repeatNewPassword" label="Repeat New Password" />
          </Box>

          <Box mt={sm ? '16px' : '32px'} ml="4px">
            <PasswordChecklist
              className={styles.classes.passwordChecklist}
              rules={['lowercase', 'number', 'capital', 'specialChar', 'minLength', 'match']}
              value={newPasswordValue}
              valueAgain={repeatNewPasswordValue}
              minLength={8}
              validColor={theme.palette.success.main}
              invalidColor={theme.palette.error.main}
              iconSize={14}
              onChange={(isValid) => setPasswordsAreValid(isValid)}
              messages={{
                minLength: 'Password must contain at least 8 characters',
                number: 'Password must contain a number',
                capital: 'Password must contain an upper case letter',
                lowercase: 'Password must contain a lower case letter',
                match: 'Passwords must match',
                specialChar: 'Password must contain a special character',
              }}
            />
          </Box>

          <Box mt={sm ? '16px' : '32px'}>
            <FormSubmitButton
              buttonText="Continue"
              disabled={!isValid || !passwordsAreValid || isSubmitting}
              onClick={handleSubmit(onSubmit)}
            />
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default AuthTemporaryPasswordView;
