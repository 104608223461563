import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Theme, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from 'tss-react/mui';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';

import { getUser, setUserPin, updateUser } from '../../api/usersApi/usersApi';
import FormTextField from '../../components/formFields/FormTextField';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormCopyTextField from '../../components/formFields/FormCopyTextField';
import { MANAGE_ACCOUNT_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { manageAccountFormSchema } from '../../helpers/formSchemas/formSchemas';
import useSnackBar from '../../hooks/useSnackBar';
import CommonButton from '../../components/buttons/CommonButton';
import { ErrorData } from '../../types';
import VerificationPopup from '../../components/VerificationPopup';

import SetPinCodePopup from './SetPinCodePopup';
import Loader from '../../components/Loader';

const useStyles = makeStyles()((theme: Theme) => ({
  formWrapper: {
    display: 'flex',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '896px',
    padding: '32px',
    gap: '32px',
    borderRadius: '16px',
    border: `1px solid ${theme.palette.secondary.dark}`,
    backgroundColor: theme.palette.primary.light,

    '@media (max-width: 725px)': {
      flexBasis: '100%',
      padding: '12px',
    },
  },
  formContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '32px',

    '@media (max-width: 725px)': {
      flexDirection: 'column',
    },
  },
  formColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '416px',
    gap: '32px',

    '@media (max-width: 725px)': {
      flexBasis: '0px',
    },
  },
  formSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  fieldHeadline: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.common.white,
  },
  text: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14.4px',
    color: theme.palette.text.secondary,
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    maxWidth: '416px',
    gap: '16px',

    '@media (max-width: 768px)': {
      alignSelf: 'flex-start',
    },
  },
}));

export interface ManageAccountFormSubmitProps {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  isPinSet: boolean;
}

const ManageAccountView = () => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();

  const userId = localStorage.getItem('userId');

  const [isSetNewPinPopupOpen, setIsSetNewPinPopupOpen] = useState<boolean>(false);
  const [isVerificationPopupOpen, setIsVerificationPopupOpen] = useState<boolean>(false);
  const [currentPin, setCurrentPin] = useState<string | null>(null);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(manageAccountFormSchema),
    defaultValues: MANAGE_ACCOUNT_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isValid, isDirty },
  } = formMethods;

  const userIdValue = watch('id');
  const isPinSet = watch('isPinSet');

  const onSubmitForm = (values: ManageAccountFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      firstName: values.firstName,
      lastName: values.lastName,
    };

    updateUser(userIdValue, submitData)
      .then((res) => {
        if (res.data) {
          setAlert((prev) => ({
            ...prev,
            message: 'Account updated successfully',
            type: 'success',
          }));

          reset({
            id: res.data.id,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            email: res.data.email,
            phone: res.data.phone,
            role: res.data.role,
            isPinSet: res.data.isPinSet,
          });
        }

        setIsFormSubmitting(false);
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();

  const handleCloseSetNewPinPopup = () => setIsSetNewPinPopupOpen(false);
  const handleCloseVerificationPopup = () => setIsVerificationPopupOpen(false);
  const handleOpenSetNewPinPopup = () => setIsSetNewPinPopupOpen(true);
  const handleOpenVerificationPopup = () => setIsVerificationPopupOpen(true);

  const getUserData = () => {
    setIsLoading(true);

    if (userId) {
      getUser(userId)
        .then(({ data }) => {
          reset({
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            role: data.role,
            isPinSet: data.isPinSet,
          });
        })
        .catch(() => {
          setAlert((prev) => ({
            ...prev,
            message: 'Cannot load account data.',
            type: 'error',
          }));
        })
        .finally(() => setIsLoading(false));
    }
  };

  const setPinCode = (newPin: string) => {
    if (!userId) return;

    const oldPin = currentPin || undefined;

    setUserPin(Number(userId), newPin, oldPin)
      .then(() => {
        getUserData();
        handleCloseSetNewPinPopup();
        setAlert((prev) => ({
          ...prev,
          message: 'PIN has been set successfully.',
          type: 'success',
        }));
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert({
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        });
      });
  };

  useEffect(() => {
    getUserData();
  }, [userId]);

  if (isLoading) {
    return <Loader colorType="warning" />;
  }

  return (
    <Box className={classes.formWrapper}>
      <FormProvider {...formMethods}>
        <form className={classes.formContainer}>
          <Box className={classes.formContentContainer}>
            <Box className={classes.formColumn}>
              <Box className={classes.formSection}>
                <Typography className={classes.fieldHeadline}>Identity</Typography>
                <FormCopyTextField entityName="Account" name="id" label="User ID" isReadOnly />
                <FormTextField name="role" label="Role *" disabled />
                <FormTextField name="firstName" label="First Name *" />
                <FormTextField name="lastName" label="Last Name *" />
              </Box>

              <Box className={classes.formSection}>
                <Typography className={classes.fieldHeadline}>Credentials</Typography>
                <FormTextField name="email" label="Email Address *" disabled />
                <FormTextField name="phone" label="Mobile Number *" disabled />
                <CommonButton buttonText="Change Password" type="infoDark" maxWidth="185px" />
              </Box>
            </Box>

            <Box className={classes.formColumn}>
              <Box className={classes.formSection}>
                <Typography className={classes.fieldHeadline}>Actions</Typography>
                <Typography className={classes.text}>
                  {isPinSet ? (
                    <>
                      {'If you forgot your PIN, please contact Client'}
                      <br />
                      {'Support to reset it'}
                    </>
                  ) : (
                    'Set up the PIN for settlement'
                  )}
                </Typography>
                <CommonButton
                  buttonText={isPinSet ? 'Change PIN' : 'Set PIN'}
                  type="infoDark"
                  maxWidth="163px"
                  onButtonClick={isPinSet ? handleOpenVerificationPopup : handleOpenSetNewPinPopup}
                />
              </Box>
            </Box>
          </Box>

          <Box className={classes.actionButtons}>
            <FormSubmitButton
              disabled={!isValid || !isDirty || isFormSubmitting}
              buttonIcon={<SaveIcon />}
              buttonText="Save"
              onClick={handleSubmit(onSubmitForm)}
            />
            <FormCancelButton disabled={!isDirty} onClick={handleCancelChanges} />
          </Box>
        </form>
      </FormProvider>

      <VerificationPopup
        isOpen={isVerificationPopupOpen}
        onSuccess={(pin) => {
          handleCloseVerificationPopup();
          handleOpenSetNewPinPopup();
          setCurrentPin(pin);
        }}
        onCancel={handleCloseVerificationPopup}
      />
      <SetPinCodePopup
        isOpen={isSetNewPinPopupOpen}
        onMainButtonClick={setPinCode}
        onSecondaryButtonClick={handleCloseSetNewPinPopup}
      />
    </Box>
  );
};

export default ManageAccountView;
