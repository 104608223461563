import api from '../service/api.service';
import { parseClientBankingFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';
import {
  PaginationQueryParams,
  FilterTypes,
  ActiveSortField,
  ResultSet,
  FirmTransaction,
} from '../../types';

const BASE_URL_NEW = 'v1/fo/banking';

export const getFirmTransactions = async ({
  size,
  page,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseClientBankingFilters(filters as FilterTypes);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);
  const targetUrl = `${BASE_URL_NEW}/transactions?size=${size}&page=${page}${queryFilters}${sortFilters}`;

  return await api.get<ResultSet<FirmTransaction>>(targetUrl);
};
