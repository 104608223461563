import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { getDataRequestTimeline } from '../../../api/requestsApi/dataApi';
import Timeline from '../../../components/Timeline';
import { TimelineEvent } from '../../../types';

const DataTimeline = () => {
  const { dataId } = useParams<string>();
  const [dataTimeline, setDataTimeline] = useState<TimelineEvent[]>([]);

  useEffect(() => {
    if (!dataId) {
      return;
    }

    getDataRequestTimeline(Number(dataId)).then((res) => {
      if (res.data) {
        setDataTimeline(res.data);
      }
    });
  }, []);

  return <Timeline data={dataTimeline} />;
};

export default DataTimeline;
