import { Fragment, useCallback, useRef, useState } from 'react';
import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { MoreVert } from '@mui/icons-material';

import theme from '../../theme';

import { MenuOption } from './types';

const useStyles = makeStyles()(() => ({
  buttonText: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '14px',
  },
}));

interface OptionsMenuProps {
  readonly onClick?: (option: MenuOption) => void;
}

const OptionsMenu = ({ onClick }: OptionsMenuProps) => {
  const { classes } = useStyles();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleMenuItemClick = useCallback(
    (option: MenuOption) => {
      onClick?.(option);
      setOpen(false);
    },
    [onClick],
  );

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  }, []);

  return (
    <Fragment>
      <IconButton ref={anchorRef} sx={{ color: theme.palette.common.white }} onClick={handleToggle}>
        <MoreVert />
      </IconButton>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="auto"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
              sx={{
                borderRadius: '12px',
                backgroundColor: theme.palette.primary.light,
                border: `1px solid ${theme.palette.secondary.dark}`,
                boxShadow: `
                  0px 32px 40px rgba(0, 0, 0, 0.08),
                  0px 1px 1px rgba(0, 0, 0, 0.08)
                `,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  <MenuItem
                    className={classes.buttonText}
                    onClick={() => handleMenuItemClick(MenuOption.Details)}
                  >
                    {MenuOption.Details}
                  </MenuItem>
                  <MenuItem
                    className={classes.buttonText}
                    onClick={() => handleMenuItemClick(MenuOption.Edit)}
                  >
                    {MenuOption.Edit}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default OptionsMenu;
