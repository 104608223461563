import axios, { AxiosError, AxiosInstance } from 'axios';

import { refreshToken as refreshAccessToken } from '../authApi/authApi';

const api: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}`,
});

const getAccessToken = (): string | null => {
  return localStorage.getItem('access_token');
};

const getRefreshToken = (): string | null => {
  return localStorage.getItem('refresh_token');
};

const logout = (): void => {
  localStorage.clear();
  sessionStorage.clear();

  window.location.href = '/sign-in';
};

const getNewAccessToken = async (): Promise<string | null> => {
  const refreshToken = getRefreshToken();
  const accessToken = getAccessToken();

  if (!refreshToken || !accessToken) {
    throw new Error('No tokens available');
  }

  try {
    const { data } = await refreshAccessToken({ refreshToken, accessToken });

    localStorage.setItem('access_token', data.accessToken);

    return data.accessToken;
  } catch (error) {
    throw new Error('Failed to refresh access token');
  }
};

api.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const { response, config } = error;

    if (config && response?.status === 401) {
      try {
        const newAccessToken = await getNewAccessToken();

        if (newAccessToken) {
          config.headers.set('Authorization', `Bearer ${newAccessToken}`);

          return api(config);
        }
      } catch (refreshError) {
        console.error('Unable to refresh token:', refreshError);
        logout();
      }
    }

    return Promise.reject(error);
  },
);

export default api;
