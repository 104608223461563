import { getCaseTypes } from '../../api/casesApi/caseTypesApi';
import { getClientsList } from '../../api/clientsApi/clientsApi';
import { getDefendantsList } from '../../api/defendantsApi/defendantsApi';
import { getFirmsList } from '../../api/firmsApi/firmsApi';
import { getTrustAccounts } from '../../api/firmsApi/trustAccountsApi';
import { CaseTypeData, PaginationQueryParams } from '../../types';

import { CaseAttorneyFeesConfigSubmitProps } from './types';

export const mapPlaintiffsQueryResults = (
  array: { id: string; firstName: string; lastName: string }[],
) => {
  return array.map((item) => ({
    id: item.id,
    label: `${item.firstName} ${item.lastName}`,
  }));
};

export const mapDefendantsQueryResults = (array: { id: string; name: string }[]) => {
  return array.map((item) => ({
    id: item.id,
    label: item.name,
  }));
};

export const handleGetPlaintiffs = async ({
  page,
  size,
  count = true,
  match = '',
  filters,
}: PaginationQueryParams) => {
  return await getClientsList({ page, size, count, match, filters });
};

export const handleGetDefendants = async ({
  page,
  size,
  count = true,
  match = '',
  filters,
}: PaginationQueryParams) => {
  return await getDefendantsList({ page, size, count, match, filters });
};

export const handleGetFirms = async ({
  page,
  size,
  count = true,
  match = '',
  filters,
}: PaginationQueryParams) => {
  return await getFirmsList({ page, size, count, match, filters });
};

export const mapFirmsQueryResults = (
  array: { id: string; name: string }[],
  primaryFirmId?: string,
) => {
  const result = array.map((item) => ({
    id: item.id,
    label: item.name,
  }));

  return primaryFirmId ? result.filter((rItem) => rItem.id !== primaryFirmId) : result;
};

export const mapFeesFormValues = (values: CaseAttorneyFeesConfigSubmitProps) => {
  const firmsAttyFeesConfigs = values?.secondaryFirms?.length
    ? values?.secondaryFirms.map((firm) => ({
        fixedAmount: firm.config?.fixedFeeAmount
          ? Number(firm.config?.fixedFeeAmount.replace(/,/g, ''))
          : null,
        variableFeePercent: firm.config?.variableFeePercent
          ? Number(firm.config?.variableFeePercent.replace(/,/g, ''))
          : null,
        maximumAmount: firm?.config?.variableFeeMaximumAmount
          ? Number(firm.config?.variableFeeMaximumAmount.replace(/,/g, ''))
          : null,
        firmId: firm?.id,
      }))
    : [];

  const submitData = {
    case: {
      variableFeePercent: values.caseFeesConfig?.variableFeePercent
        ? Number(values.caseFeesConfig?.variableFeePercent.replace(/,/g, ''))
        : null,
      maximumAmount: values.caseFeesConfig?.maximumAmount
        ? Number(values.caseFeesConfig?.maximumAmount.replace(/,/g, ''))
        : null,
    },
    secondaryFirms: firmsAttyFeesConfigs,
  };

  return submitData;
};

export const handleGetCaseTypes = async ({
  page,
  size,
  count = true,
  match = '',
  filters,
  includeGlobal = true,
}: PaginationQueryParams) => {
  return await getCaseTypes({ page, size, count, match, filters, includeGlobal });
};

export const mapCaseTypesQueryResults = (array: CaseTypeData[]) => {
  return array.map((item) => ({
    id: item.id,
    label: item.name,
  }));
};

export const handleGetTrustAccounts = async ({
  page,
  size,
  count = true,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  return await getTrustAccounts({ page, size, count, filters, activeSortField });
};

export const mapTrustAccountsQueryResults = (array: { id: string; name: string }[]) => {
  return array.map((item) => ({
    id: item.id,
    label: item.name,
  }));
};
