import api from '../service/api.service';

const BASE_URL = 'v1/fo/auth';

interface LoginRequestBody {
  email: string;
  password: string;
  verificationCode: string;
}

interface LoginSuccessResponse {
  accessToken: string;
  accessTokenExpiresAt: string;
  refreshToken: string;
}

interface SetPermanentPasswordRequestBody {
  email: string;
  password: string;
  newPassword: string;
}

interface SendVerificationCodeRequestBody {
  email: string;
  password: string;
}

interface SendVerificationCodeSuccessResponse {
  sendTimeoutSec: number;
}

interface RefreshTokenRequestBody {
  refreshToken: string;
  /**
   * Can be expired.
   */
  accessToken: string;
}

interface RefreshTokenSuccessResponse {
  accessToken: string;
  accessTokenExpiresAt: string;
}

interface ForgotPasswordRequestBody {
  email: string;
}

interface ConfirmForgotPasswordRequestBody {
  email: string;
  newPassword: string;
  passwordRecoveryCode: string;
}

export const login = async (body: LoginRequestBody) => {
  const targetUrl = `${BASE_URL}/login`;

  return await api.put<LoginSuccessResponse>(targetUrl, body);
};

/**
 * Sets a permanent password for a firm user in the following cases:
 * - password reset was required
 * - password is temporary
 */
export const setPermanentPassword = async (body: SetPermanentPasswordRequestBody) => {
  const targetUrl = `${BASE_URL}/set-permanent-password`;

  return await api.put<void>(targetUrl, body);
};

export const sendVerificationCode = async (body: SendVerificationCodeRequestBody) => {
  const targetUrl = `${BASE_URL}/send-verification-code`;

  return await api.put<SendVerificationCodeSuccessResponse>(targetUrl, body);
};

export const refreshToken = async (body: RefreshTokenRequestBody) => {
  const targetUrl = `${BASE_URL}/refresh-token`;

  return await api.put<RefreshTokenSuccessResponse>(targetUrl, body);
};

/**
 * Sends a recovery code to user email that will be used to set a new permanent password.
 * This flow is suitable for both forgotten permanent and forgotten temporary password in any status.
 */
export const sendForgotPasswordEmail = async (body: ForgotPasswordRequestBody) => {
  const targetUrl = `${BASE_URL}/forgot-password`;

  return await api.put<void>(targetUrl, body);
};

export const confirmForgotPassword = async (body: ConfirmForgotPasswordRequestBody) => {
  const targetUrl = `${BASE_URL}/confirm-forgot-password`;

  return await api.put<void>(targetUrl, body);
};
