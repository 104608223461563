import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { Box, Typography, Theme } from '@mui/material';

import usePageTitle from '../../hooks/usePageTitle';
import GoBackButton from '../buttons/GoBackButton';

const allowedPathsForBackButton = [
  'document-request',
  'create-document-request',
  'signature-request',
  'create-data-request',
  'data-request',
  'deduction',
  'create-deduction',
  'create-settlement',
  'settlement',
];

const useStyles = makeStyles()((theme: Theme) => ({
  topBarWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '20px 0',
  },
  topBarInnerContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    color: theme.palette.common.white,
    gap: '10px',
  },
  topBarHeadlineText: {
    fontWeight: 600,
    fontSize: '34px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontFamily: 'Poppins',
    lineHeight: '41px',
  },
}));

const TopBar = () => {
  const location = useLocation();

  const { pageTitle } = usePageTitle();

  const [currentLocationName, setCurrentLocationName] = useState<string>(pageTitle);

  const splittedLocationName = location.pathname.split('/').filter((loc) => !!loc);

  const creationRoutes = splittedLocationName.filter((location) => location.includes('create'));
  const editRoutes = splittedLocationName.filter((location) => location.includes('home'));

  const styles = useStyles();

  useEffect(() => {
    if (splittedLocationName.length === 1) {
      setCurrentLocationName(splittedLocationName[0].replaceAll('-', ' '));
      return;
    }

    if (creationRoutes.length) {
      setCurrentLocationName(creationRoutes[0].replaceAll('-', ' '));
      return;
    }

    if (editRoutes.length) {
      setCurrentLocationName(pageTitle);
      return;
    }

    if (splittedLocationName[0] === 'manage-account') {
      setCurrentLocationName('Manage Account');
      return;
    }

    if (
      location.pathname === '/requests/document-requests' ||
      location.pathname === '/requests/data-requests' ||
      location.pathname === '/requests/signature-requests'
    ) {
      setCurrentLocationName('requests');
      return;
    }

    if (
      location.pathname === '/payments/deductions' ||
      location.pathname === '/payments/settlements'
    ) {
      setCurrentLocationName('payments');
      return;
    }

    if (
      location.pathname === '/banking/accounts' ||
      location.pathname === '/banking/transactions'
    ) {
      setCurrentLocationName('banking');
      return;
    }

    setCurrentLocationName(pageTitle);
  }, [location.pathname, pageTitle]);

  const handleShowBackButton = () => {
    const hasAllowedPaths = allowedPathsForBackButton.some((allowedPath) =>
      splittedLocationName.includes(allowedPath),
    );

    if (splittedLocationName.length === 1) {
      return false;
    }

    if (hasAllowedPaths) {
      return true;
    }

    if (splittedLocationName.length > 1) {
      if (
        splittedLocationName.includes('payments') ||
        splittedLocationName.includes('requests') ||
        splittedLocationName.includes('banking')
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Box className={styles.classes.topBarWrapper}>
      <Box className={styles.classes.topBarInnerContainer}>
        {splittedLocationName[0] !== 'manage-account' && handleShowBackButton() && <GoBackButton />}
        <Typography
          className={styles.classes.topBarHeadlineText}
          textTransform={editRoutes.length ? 'initial' : 'capitalize'}
        >
          {currentLocationName}
        </Typography>
      </Box>
    </Box>
  );
};

export default TopBar;
