import api from '../service/api.service';

import { PaginationQueryParams, ResultSet, TrustAccount, TrustAccountShort } from '../../types';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';

const BASE_URL = 'v1/fo/banking/trust-accounts';

export const getTrustAccount = async (accountId: number) => {
  const targetUrl = `${BASE_URL}/${accountId}`;

  return await api.get<TrustAccount>(targetUrl);
};

export const updateTrustAccount = async (
  accountId: number,
  data: {
    name?: string;
    accountNumber?: string;
    routingNumber?: string;
    type?: string;
  },
) => {
  const targetUrl = `${BASE_URL}/${accountId}`;

  return await api.put<TrustAccount>(targetUrl, data);
};

export const deleteTrustAccount = async (accountId: number) => {
  const targetUrl = `${BASE_URL}/${accountId}`;

  return await api.delete<void>(targetUrl);
};

export const getTrustAccounts = async ({
  size,
  page,
  count,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField);
  const targetUrl = `${BASE_URL}?size=${size}&page=${page}&count=${count}${queryFilters}${sortFilters}`;

  return await api.get<ResultSet<TrustAccountShort>>(targetUrl);
};

export const createTrustAccount = async (data: {
  name: string;
  type: string;
  accountNumber: string;
  routingNumber: string;
}) => {
  return await api.post<TrustAccount>(BASE_URL, data);
};

/**
 * Start/retry/continue passport provisioning for a specific trust account.
 * Can be performed in case if it has not been performed before, OR if the previous provisioning attempt ended with a non-critical error.
 */
export const startTrustAccountProvisioning = async (accountId: number) => {
  const targetUrl = `${BASE_URL}/${accountId}/start-provisioning`;

  return await api.post<void>(targetUrl);
};
